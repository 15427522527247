import styled from "styled-components";
import LoginForm from "../../components/form/login";

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Login = () => {

    return (
        <LoginContainer>
            <LoginForm></LoginForm>
        </LoginContainer>
    );
};

export default Login