import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { BreakpointsQuery } from "../../constants/device";
import { useTranslation } from "react-i18next";
import { Color } from "../../constants/color";
import {useAuth} from "../../provider/authProvider";

const MenuShadow = styled.div <{
  $logged: boolean
}>`
  box-shadow: 0 6px 3px -3px #e9eff6;
  background-color: ${Color.BLUE};
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  display: ${props => props.$logged ? 'flex' : 'none'};
  justify-content: center;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 3.5rem;
  column-gap: 4rem;
  width: auto;
  text-align: center;
  @media ${BreakpointsQuery.tablet} {
    //width: auto;
    //column-gap: 0.8rem;
  }
`;

const MenuButton = styled(NavLink)`
  color: ${Color.WHITE};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: normal;
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
`;

const Menu = () => {
  const { t } = useTranslation();
  const { token } = useAuth()
  return (
    <MenuShadow $logged={token !== undefined}>
      <MenuContainer>
        <MenuButton to={"/dashboard"}>{t("menu-dashboard")}</MenuButton>
        <MenuButton to={"/prenota-un-corso"}>
          {t("menu-book-course")}
        </MenuButton>
        <MenuButton to={"/corsi-prenotati"}>
          {t("menu-reserved-courses")}
        </MenuButton>
        <MenuButton to={"/attestati"}>
          {t("menu-certificate-management")}
        </MenuButton>
        <MenuButton to={"/anagrafica-dipendenti"}>
          {t("menu-employee-registry")}
        </MenuButton>
      </MenuContainer>
    </MenuShadow>
  );
};

export default Menu;
