import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";

import Combobox from "../combobox";
import { Color } from "../../constants/color";
import { BE_BASE_URL } from "../../utils/config";
import type { CorsoType, LabelValueType } from "../../types";
import Select from "../select";

function FormazioneBarChart({
  chartWidth,
  chartHeight,
}: {
  chartWidth: string;
  chartHeight: string;
}) {
  const { t } = useTranslation();

  const mapMonths = new Map<number, string>(
    Array.from({ length: 12 }, (_, i) => [i + 1, t(`months.${i + 1}`)])
  );
  const monthsOptions: LabelValueType[] = [];
  mapMonths.forEach((v, k) => {
    monthsOptions.push({
      label: v,
      value: `${k}`,
    });
  });

  const [domainTables, setDomainTables] = useState<{
    corsi: CorsoType[];
    sedi: string[];
  }>({
    corsi: [],
    sedi: [],
  });
  const [chartData, setChartData] = useState<any>();
  const [filters, setFilters] = useState<{
    corsi: string[];
    sedi: string[];
    mesi: number[];
  }>({
    corsi: [],
    sedi: [],
    mesi: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  });

  const readCorsi = async () => {
    const response = await fetch(`${BE_BASE_URL}/courses`);
    return await response.json();
  };

  const readSedi = async () => {
    const response = await fetch(`${BE_BASE_URL}/locations`);
    return await response.json();
  };

  const readChartData = async (filters: {
    corsi: string[];
    sedi: string[];
    mesi: number[];
  }) => {
    const sParams = new URLSearchParams();
    sParams.append("companyId", "2");
    if (filters.corsi.length > 0) {
      sParams.append("courseId", filters.corsi.join(","));
    }
    if (filters.sedi.length > 0) {
      sParams.append("location", filters.sedi.join(","));
    }
    if (filters.mesi.length > 0) {
      sParams.append("month", filters.mesi.join(","));
    }
    const response = await fetch(
      `${BE_BASE_URL}/dashboard/formazione?${sParams}`
    );
    if (response.ok && response.status === 200) {
      const json = await response.json();
      return json.map(
        (item: { month: number; trainingHours: number; employees: number }) => {
          return {
            name: mapMonths.get(item.month),
            oreFormazione: item.trainingHours,
            mediaDipendente: (item.trainingHours / item.employees).toFixed(2),
          };
        }
      );
    }
    return [];
  };

  useEffect(() => {
    Promise.all([readCorsi(), readSedi(), readChartData(filters)])
      .then(([corsi, sedi, cData]) => {
        setDomainTables({
          corsi,
          sedi,
        });
        setChartData(cData);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    readChartData(filters).then((cData) => {
      setChartData(cData);
    });
    // eslint-disable-next-line
  }, [filters]);

  const corsi = domainTables?.corsi
    ? domainTables.corsi.map((c) => {
        return {
          label: `[${c.code}] ${c.name}`,
          value: `${c.id}`,
        };
      })
    : [];

  const sedi = domainTables?.sedi
    ? domainTables.sedi.map((s, index) => {
        return {
          label: s,
          value: s,
        };
      })
    : [];

  function handleFiltroCorsiChange(filter: LabelValueType[]) {
    // console.log("Formazione chart filtro corso cambiato", filter);
    setFilters({
      ...filters,
      corsi: filter.map((f) => f.value),
    });
  }

  function handleFiltroSediChange(filter: LabelValueType[]) {
    // console.log("Formazione chart filtro sede cambiato", filter);
    setFilters({
      ...filters,
      sedi: filter.map((f) => f.value),
    });
  }

  function handleFiltroPeriodoInizioChange(filter: LabelValueType) {
    // console.log("Formazione chart filtro periodo inizio cambiato", filter);
    let inizio = parseInt(filter.value);
    // Prendo il max da filters.mesi
    let finePeriodo = filters.mesi.reduce((acc, cur) => {
      return Math.max(acc, cur);
    }, 0);
    if (finePeriodo === inizio) {
      // mesi = array con gli interi da finePeriodo a inizio
      setFilters({
        ...filters,
        mesi: [inizio],
      });
      return;
    }

    if (finePeriodo < inizio) {
      const _inizio = finePeriodo;
      finePeriodo = inizio;
      inizio = _inizio;
    }

    // mesi = array con gli interi da finePeriodo a inizio
    setFilters({
      ...filters,
      mesi: Array.from(
        { length: finePeriodo - inizio + 1 },
        (_, i) => i + inizio
      ),
    });
  }

  function handleFiltroPeriodoFineChange(filter: LabelValueType) {
    // console.log("Formazione chart filtro periodo fine cambiato", filter);
    let fine = parseInt(filter.value);
    // Prendo il min da filters.mesi
    let inizioPeriodo = filters.mesi.reduce((acc, cur) => {
      return Math.min(acc, cur);
    }, 12);

    if (fine === inizioPeriodo) {
      setFilters({
        ...filters,
        mesi: [fine],
      });
      return;
    }

    if (fine < inizioPeriodo) {
      const _fine = inizioPeriodo;
      inizioPeriodo = fine;
      fine = _fine;
    }

    // mesi = array con gli interi da inizioPeriodo a fine
    setFilters({
      ...filters,
      mesi: Array.from(
        { length: fine - inizioPeriodo + 1 },
        (_, i) => i + inizioPeriodo
      ),
    });
  }

  return (
    <div
      style={{
        padding: "1rem .5rem",
        color: Color.BLUE,
        fontWeight: "bold",
      }}
    >
      <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>
        {t("dashboard-chart-training")}
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-around",
            gap: ".1em",
            paddingBlock: ".1em",
          }}
        >
          <Combobox
            options={corsi}
            placeholder="Filtri corsi"
            onChange={handleFiltroCorsiChange}
          />
          <Combobox
            options={sedi}
            placeholder="Filtri sedi"
            onChange={handleFiltroSediChange}
          />
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <Select
              label="Periodo"
              placeholder="Inizio"
              options={monthsOptions}
              onChange={handleFiltroPeriodoInizioChange}
            />
          </div>
          <Select
            placeholder="Fine"
            options={monthsOptions}
            onChange={handleFiltroPeriodoFineChange}
          />
        </div>
        {/* CHART FORMAZIONE */}
        <div
          style={{
            display: "flex",
            gap: ".3em",
            width: chartWidth,
            height: chartHeight,
            padding: "1em .5em",
            alignSelf:"center"
          }}
        >
          <ResponsiveContainer
            width="100%"
            height="100%"
            style={{ backgroundColor: "#FF000" }}
          >
            {/* CHART */}
            <BarChart
              width={800}
              height={300}
              data={chartData}
              margin={{ bottom: 10 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />
              <Bar dataKey="oreFormazione" fill={Color.LIGHT_GREEN} />
              <Bar dataKey="mediaDipendente" fill={Color.DARK_BLUE} />
            </BarChart>
          </ResponsiveContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "3em",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  flexShrink: 0,
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  backgroundColor: Color.LIGHT_GREEN,
                }}
              />
              <div>{t("dashboard-chart-training-hours")}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  flexShrink: 0,
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  backgroundColor: Color.DARK_BLUE,
                }}
              />
              <div>{t("dashboard-chart-average-employees")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormazioneBarChart;
