import { useState } from "react";
import * as Ariakit from "@ariakit/react";
import styled from "styled-components";

import { LabelValueType } from "../../types";
import "./select_style.css";

const Sep = styled.span`
  width: 1px;
  border-left: 1px solid var(--grey500);
  height: 50%;
`;

export default function Select({
  label,
  placeholder = "Seleziona un'opzione",
  options,
  onChange,
}: {
  label?: string;
  placeholder?: string;
  options: LabelValueType[];
  onChange?: (value: LabelValueType) => void;
}) {
  const [selectedItem, setSelectedItem] = useState<string>("");

  const select = Ariakit.useSelectStore({
    value: selectedItem,
    setValue: (value) => {
      // console.log(`log ${value}`);
      setSelectedItem(value);
      if (onChange) onChange(options.find((o) => o.value === value)!);
    },
  });

  function renderValue(value: string) {
    // console.log("combobox render value", value);
    if (value.length === 0) return placeholder;
    const oIndex = options.findIndex((o) => o.value === value);
    return options[oIndex].label;
  }

  return (
    <div className="wrapper">
      <Ariakit.SelectProvider store={select}>
        {label && (
          <Ariakit.SelectLabel className="label">{label}</Ariakit.SelectLabel>
        )}
        <Ariakit.Select className="button">
          {renderValue(selectedItem)}
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <Sep />
            <Ariakit.SelectArrow />
          </div>
        </Ariakit.Select>
        <Ariakit.SelectPopover gutter={4} sameWidth className="popover">
          {options.map((o) => (
            <Ariakit.SelectItem
              key={o.value}
              value={o.value}
              className="select-item"
            >
              <Ariakit.SelectItemCheck
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
              {o.label}
            </Ariakit.SelectItem>
          ))}
        </Ariakit.SelectPopover>
      </Ariakit.SelectProvider>
    </div>
  );
}
