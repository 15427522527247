import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import {useState} from "react";
import {Icons} from "../../constants/icons";

interface Props {
    onSearch(input : string): void | undefined
    label?: string;
    width?: string;
}

const CoursesSearchWrapper = styled.div <{
    $width: string;
}>`
    display: flex;
    width: ${props => props.$width ?? '80%'};
    min-height: 1.8rem;
`;

const Search = styled.input`
    border-color: ${Color.LIGHT_BLUE};
    border-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    flex-grow: 8;
    text-align: center;
    font-style: italic;
`;

const ButtonSearch = styled.button`
    flex-grow: 1;
    background-color: ${Color.LIGHT_BLUE};
    color: ${Color.WHITE};
    font-size: 14px;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    text-transform: uppercase;
    
`;

const CustomSearch = ( {onSearch, label, width} : Props) => {
    const { t } = useTranslation();
    const [input, setInput] = useState('');
    return (
        <CoursesSearchWrapper $width={width!!}>
            <Search
                placeholder={label ?? t('reserved-courses-detail-search-placeholder')}
                onInput={ e => setInput(e.currentTarget.value)}
                onKeyUp={ (e) => {
                    if (e.key === 'Enter') {
                        onSearch(input)
                    }
                }}
            />
            <ButtonSearch onClick={ () => {
                onSearch(input)
            }}>
                <img src={Icons.BUTTONS.SEARCH} alt={'search'} width={20}/>
            </ButtonSearch>
        </CoursesSearchWrapper>
    );
};

export default CustomSearch