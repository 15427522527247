import DetectorOptions from "i18next-browser-languagedetector";


const detectionOption = new DetectorOptions
(null, {
    order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],

    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupSessionStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    caches: ["localStorage", "cookie"],
    excludeCacheFor: ["cimode"],

    cookieMinutes: 10,
    cookieDomain: "sempreformati",

    htmlTag: document.documentElement,

    cookieOptions:
      {
        path: "/",
        sameSite: 'strict'
      }

  }
)

export default detectionOption