import styled from "styled-components";
import {CourseCarouselItem} from "../../../types";
import CarouselItem from "./item";
import {BreakpointsQuery} from "../../../constants/device";
import {useEffect, useState} from "react";
import {ChevronLeft, ChevronRight, Dot} from "lucide-react";
import {Color} from "../../../constants/color";

interface Props {
    courses: CourseCarouselItem[];
}

const CarouselWrapper = styled.div`
    display: flex;
    transition: display 2s;
    flex-direction: column;
    justify-items: center;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;
`;

const Carousel = styled.div`
    display: flex;
    transition: display 2s;
    flex-direction: row;
    justify-items: center;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    column-gap: 1.8rem;
    width: 60rem;
    overflow-y: visible;
    overflow-x: auto;
    height: 30rem;
    @media ${BreakpointsQuery.tablet} {
        width: 52rem;
    }
`;

const PageMarker = styled.div`
  display: flex;
    justify-content: center;
`;



const CourseCarousel = ( { courses} : Props ) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const unit = 3;
  let pages = Math.floor(courses.length / unit);
  let exceed =  courses.length % unit
  if (exceed !== 0) pages = pages + 1

  //const [page, setPage] = useState(courses.slice)

  function createPage(courses: CourseCarouselItem[], page: number) : CourseCarouselItem[] {
    let carousePages : CourseCarouselItem[] = []
    carousePages.push(...courses.slice(unit*(page), (unit*(page)) + unit))
    return carousePages;
  }

  const page : CourseCarouselItem[] = createPage(courses, currentIndex)

  useEffect( () => {

  }, [currentIndex, page])

    return (
        <CarouselWrapper>
          <Carousel>
            <ChevronLeft color={Color.GREEN}
                         size={'3rem'}
                         style={{margin: 'auto'}}
                         onClick={() => {
                           if (currentIndex > 0) setCurrentIndex(currentIndex -1)
                         }}
            />
            <ListWrapper>
                {page.map((singleCourse, index) => {
                    return (
                        <CarouselItem
                            item={singleCourse}
                            key={index}
                        />
                    );
                })}
            </ListWrapper>

            <ChevronRight color={Color.GREEN}
                          size={'3rem'}
                          style={{margin: 'auto'}}
                          onClick={() => {
                            if (currentIndex < pages -1) setCurrentIndex(currentIndex +1)
                            else setCurrentIndex(0)
                          }}
            />
          </Carousel>
          <PageMarker>
            {page.map((_singleCourse, index) => {
              return (
                  index < pages && <Dot
                      color={index === currentIndex ? Color.GREEN : Color.GREY}
                      key={index}
                      size={'3rem'}
                  />
              );
            })}
          </PageMarker>
        </CarouselWrapper>
    );
};

export default CourseCarousel