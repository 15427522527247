import styled from "styled-components";
//import {useTranslation} from "react-i18next";
import {DropdownProps} from "../../../types";
//import {useState} from "react";
import Option from "./option";
import Label from "../../label";
import {Color} from "../../../constants/color";
import {Icons} from "../../../constants/icons";

const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
`;

const DropdownContainer = styled.div`
    display: flex;
    border-color: ${Color.LIGHT_BLUE};
    border-style: solid;
    border-width: thin;
    border-radius: 20px;
    max-width: fit-content;
`;

const Search = styled.input`
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-color: ${Color.LIGHT_BLUE};
    border-style: solid;
    border-width: thin;
    height: 25px;
    width: 120px;
    padding-left: 12px;
`;

const Select = styled.select`
    font-size: 0;
    width: 30px;
    appearance: none;
    background: url(${Icons.COMMONS.ARROW}) no-repeat, linear-gradient(to right, ${Color.LIGHT_BLUE}, ${Color.LIGHT_BLUE});
    background-size: 22px;
    background-position-y: center;
    background-position-x: 1px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-color: ${Color.LIGHT_BLUE};
`;



function Dropdown({name, placeholder, options}: DropdownProps) {
    //const { t } = useTranslation();
    //const [isDisabled, setIsDisabled] = useState(false);
    return (
        <DropdownWrapper>
        <Label text={name} textTransform={'uppercase'} paddingLeft={12} paddingBottom={4}/>
        <DropdownContainer>
            <Search placeholder={placeholder}></Search>
            <Select name={name} defaultValue={placeholder}>
                <Option value={options[0].value} label={options[0].label}/>
            </Select>
        </DropdownContainer>
        </DropdownWrapper>
    );
}

export default Dropdown