import styled from "styled-components";
import {Color} from "../../../../constants/color";
import {Icons} from "../../../../constants/icons";
import Label from "../../../label";
import Button from "../../../button/button";
import {CourseCarouselItem} from "../../../../types";
import {goToPage} from "../../../../utils/utils";
import {Building2, MapPin, Users} from "lucide-react";
import {BreakpointsQuery} from "../../../../constants/device";

interface Props {
    item: CourseCarouselItem
}

const StyledItem = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${Color.WHITE};
    box-shadow: 0 0 20px 10px ${Color.SHADOW};
    font-weight: bolder;
    border-radius: 20px;
    max-height: 9rem;
    max-width: 62rem;
    height: 26rem;
    
`;

const CourseImage = styled.div`
    flex-grow: 1;
    min-height: 7.4rem;
    width: 10rem;
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        max-width: 100%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
`;

const Name = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 2rem;
    background-color: ${Color.LIGHT_BLUE};
    border-top-right-radius: 20px;

    div {
        color: ${Color.WHITE};
        text-align: center;
        vertical-align: center;
        margin: 0.5rem 2.2rem;
    }
`;

const Column = styled.div`
    display: flex;
    row-gap: 1.6rem;
    flex-direction: column;
    flex-grow: 10;
    max-width: 50rem;
    @media ${BreakpointsQuery.tablet} {
        max-width: 43rem;
    }
`;

const Info = styled.div`
    display: flex;
    row-gap: 0.6rem;
    flex-direction: row;
    align-items: center;
    min-width: 100%;
    margin-bottom: 1rem;
    button {
        margin-top: 1.2rem;
        margin-right: 1rem;
    }
`;

const InfoRow = styled.div`
    display: flex;
    max-width: 50rem;
    padding-left: 2rem;
    column-gap: 10rem;
    
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    width: 40rem;
    align-items: center;
    div {
        text-align: left;
        //max-width: 8rem;
    }
`;

const SubInfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1.4rem;
    img {
        flex-grow: 1;
    }
    div {
        flex-grow: 5;
        text-align: left;
        max-width: 9rem;
    }
`;


const ListItem = ( {item} : Props ) => {
/*    const mockCourse : CoursePage = {
        name: 'aggiornamento addetti antincendio livello 3 (3 agg)',
        availableDates:
        [
            {
                day: 30,
                month: 9,
                mode: 'In presenza',
                classroom: 'Aula Prova',
                city: 'Genova',
                address: 'Via al molo giano',
                duration: 8,
                detail: {
                    firstDate: '30 sett',
                    secondDate: '1 ott',
                    firstHour: '14:00-18:00',
                    secondHour: '09:00-13:00',
                }
            }
        ]
    }*/

    return (
        <StyledItem>
            <CourseImage>
                <img alt={'Immagine corso'} src={item.image}/>
            </CourseImage>
            <Column>
                    <Name>
                        <Label text={item.name}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'18px'}
                               textAlign={'center'}
                               color={Color.LIGHT_BLUE}
                        />
                    </Name>
                <InfoRow>
                    <Info>
                        <InfoColumn>
                            <SubInfoColumn>
                                <Users color={Color.LIGHT_BLUE}/>
                            </SubInfoColumn>
                            <SubInfoColumn>
                                <Label text={'tipo di corso'}
                                       textTransform={'uppercase'}
                                       fontWeight={'bold'}
                                       fontSize={'12px'}
                                       textAlign={'center'}
                                       color={Color.DARK_GREY}
                                />
                                <Label text={item.mode}
                                       textTransform={'uppercase'}
                                       fontWeight={'bold'}
                                       fontSize={'15px'}
                                       textAlign={'center'}
                                       color={Color.LIGHT_BLUE}
                                />
                            </SubInfoColumn>
                        </InfoColumn>
                        <InfoColumn>
                            <SubInfoColumn>
                                <MapPin color={Color.LIGHT_BLUE}/>
                            </SubInfoColumn>
                            <SubInfoColumn>
                                <Label text={'citta'}
                                       textTransform={'uppercase'}
                                       fontWeight={'bold'}
                                       fontSize={'12px'}
                                       textAlign={'center'}
                                       color={Color.DARK_GREY}
                                />
                                <Label text={item.headquarter}
                                       textTransform={'uppercase'}
                                       fontWeight={'bold'}
                                       fontSize={'15px'}
                                       textAlign={'center'}
                                       color={Color.LIGHT_BLUE}
                                />
                            </SubInfoColumn>
                        </InfoColumn>
                        <InfoColumn>
                            <SubInfoColumn>
                                <Building2 color={Color.LIGHT_BLUE}/>
                            </SubInfoColumn>
                            <SubInfoColumn>
                                <Label text={'sede del corso'}
                                       textTransform={'uppercase'}
                                       fontWeight={'bold'}
                                       fontSize={'12px'}
                                       textAlign={'center'}
                                       color={Color.DARK_GREY}
                                />
                                <Label text={item.address}
                                       textTransform={'capitalize'}
                                       fontWeight={'bold'}
                                       fontSize={'15px'}
                                       textAlign={'center'}
                                       color={Color.LIGHT_BLUE}
                                />
                            </SubInfoColumn>
                        </InfoColumn>
                        <Button
                            label={'Prenota'}
                            color={Color.BLUE}
                            icon={Icons.COMMONS.CALENDAR}
                            onClick={ () => goToPage('corso', item.name.toLowerCase())}
                        />
                    </Info>
                </InfoRow>
            </Column>
        </StyledItem>
    )
}

export default ListItem