import "./App.css";

import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Home from "./pages/home";
import Login from "./pages/login";
import ReservedCourses from "./pages/reserved-courses";

import Footer from "./components/footer";
import Menu from "./components/menu";
import NavBar from "./components/navbar";

import "./localizations/i18nComponent";
import AuthProvider from "./provider/authProvider";
import { ProtectedRoute } from "./routes/protected-route";
import BookCourse from "./pages/book-course";
import Course from "./pages/course";
import EmployeeRegistry from "./pages/employee-registry";
import AttestatiPage from "./pages/certificates";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <AuthProvider>
      <Layout>
        <NavBar />
        <Menu/>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/prenota-un-corso" element={<BookCourse />} />
            <Route path="/corsi-prenotati" element={<ReservedCourses />} />
            <Route path="/corso/:name" element={<Course />} />
            <Route path="/corso/:name/:date" element={<Course />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/anagrafica-dipendenti" element={<EmployeeRegistry />} />
            <Route path="/attestati" element={<AttestatiPage />} />
          </Route>
        </Routes>
        <Footer />
      </Layout>
    </AuthProvider>
  );
}

export default App;
