import {PhoneNumberProps} from "../../../types";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {Color} from "../../../constants/color";


const StyledNumber = styled(NavLink)`
    text-decoration: none;
    color: ${Color.LIGHT_BLUE};
    font-family: "Avenir Next", sans-serif;
    font-weight: bold;
`;

const PhoneNumber = ({ phone } : PhoneNumberProps) => {
    return (
        <StyledNumber to={`tel:${phone}`}>{phone}</StyledNumber>
    )
}

export default PhoneNumber;