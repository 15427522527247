import React, { useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";

import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import { CourseEmployees } from "../../resources/mock";

import * as Ariakit from "@ariakit/react";
import {
  Bell,
  CalendarCog,
  CalendarDays,
  ChevronDown,
  FileDown,
  Search,
  User,
} from "lucide-react";
import { goToPage } from "../../utils/utils";
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../form/select";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

//Definisco mappa sedi
const sedeAttestati: { [key: string]: string } = {
  "": "TUTTE",
  GENOVA: "GENOVA",
  SAVONA: "SAVONA",
  IMPERIA: "IMPERIA",
};

// Definisco una mappa con lo stato attestati:
// 1 = valido
// 2 = in scadenza
// 3 = scaduto
const statoAttestati: { [key: number]: string } = {
  0: "TUTTI",
  1: "VALIDO",
  2: "IN SCADENZA",
  3: "SCADUTO",
};

export default function RicercaPerDipendente() {
  const [attestati, setAttestati] = React.useState(CourseEmployees);

  const [filters, setFilters] = React.useState({
    discente: "",
    sede: "",
    stato: 0,
  });

  const debouncedFilters = useDebounce(filters, 300);

  useEffect(() => {
    let _attestati = CourseEmployees;
    if (debouncedFilters.discente) {
      _attestati = _attestati.filter((c) =>
        c.name.toLowerCase().includes(debouncedFilters.discente.toLowerCase())
      );
    }

    if (debouncedFilters.sede) {
      _attestati = _attestati.filter((c) =>
        c.headquarter
          .toLowerCase()
          .includes(debouncedFilters.sede.toLowerCase())
      );
    }

    if (debouncedFilters.stato) {
      _attestati = _attestati.filter(
        (c) => c.status_expire === debouncedFilters.stato
      );
    }

    setAttestati(_attestati);
  }, [debouncedFilters]);

  const selectSede = Ariakit.useSelectStore({
    value: filters.sede,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, sede: value }));
    },
  });

  const selectStato = Ariakit.useSelectStore({
    value: `${filters.stato}`,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, stato: +value }));
    },
  });

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchDiscente">NOME O COGNOME</label>
          <div
            style={{
              position: "relative",
              minWidth: "400px",
            }}
          >
            <input
              name="searchDiscente"
              type="text"
              placeholder="Ricerca nome o cognome"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, discente: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SelectProvider store={selectSede}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              SEDE AZIENDALE
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => sedeAttestati[`${value}`]}
            >
              {Object.entries(sedeAttestati).map(([key, value]) => (
                <SelectItem key={`sedeAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
        <SelectProvider store={selectStato}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              STATO
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => statoAttestati[+value]}
            >
              {Object.entries(statoAttestati).map(([key, value]) => (
                <SelectItem key={`statoAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
      </SearchContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "-1.5rem",
            fontSize: ".8rem",
            fontWeight: "bold",
            color: Color.LIGHT_BLUE,
          }}
        >
          <p
            style={{
              width: "100px",
            }}
          ></p>
          <p
            style={{
              width: "380px",
            }}
          >
            NOME E COGNOME
          </p>
          <p
            style={{
              width: "280px",
            }}
          >
            CODICE FISCALE
          </p>
          <p
            style={{
              width: "255px",
            }}
          >
            SEDE AZIENDALE
          </p>
          <p>ATTESTATI</p>
        </div>

        {attestati.map((c) => (
          <div
            style={{
              width: "100%",
              filter: "drop-shadow(4px -4px 5px var(--shadow800))",
            }}
          >
            <CourseAccordion
              icon={<User color={Color.LIGHT_BLUE} size={48} />}
              key={c.name}
              name={c.name}
              cf={c.cf}
              headquarter={c.headquarter}
              status={c.status_expire}
              dateExp={c.date_exp}
              attestati={c.certificates}
            />
          </div>
        ))}
      </div>
    </Layout>
  );
}

const Disclosure = styled(Ariakit.Disclosure)`
  border-radius: 0.8rem;
  background-color: ${Color.WHITE};
  width: 100%;
  position: relative;
  overflow: hidden;
  border: none;
  padding: 0.5rem 1.5rem;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 1rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  & p[role='button'] {
    position: absolute;
    right: 0;
    top: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: ${Color.GREEN};
    color: ${Color.WHITE};
    font-weight: bold;
`;

const DisclosureContent = styled(Ariakit.DisclosureContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-height: 24rem;
  width: calc(100% - 0.8rem);
  margin: -8px auto 0 auto;

  background-color: ${Color.CERAMIC};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding: 1.6rem 2rem;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
`;

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: 100px auto 150px 200px 150px 150px 60px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0;
  padding: 0.3em 1.5em;
  border-radius: 8px;
  font-size: 1.6em;
  font-weight: bold;
  cursor: pointer;
`;

function CourseAccordion({
  icon,
  name,
  cf,
  headquarter,
  status,
  dateExp,
  attestati,
}: {
  icon?: React.ReactNode;
  name: string;
  cf: string;
  headquarter: string;
  status: number;
  dateExp: string;
  attestati: any[];
}) {
  const [filterdAttestati, setFilteredAttestati] = React.useState(attestati);
  const [searchCorso, setSearchCorso] = React.useState("");

  const debauncedSearchCorso = useDebounce(searchCorso, 300);

  useEffect(() => {
    if (debauncedSearchCorso) {
      setFilteredAttestati(
        attestati.filter((a) =>
          a.name.toLowerCase().includes(debauncedSearchCorso.toLowerCase())
        )
      );
    } else {
      setFilteredAttestati(attestati);
    }
  }, [debauncedSearchCorso, attestati]);

  function renderScadenza(status_expire: number, dateExp: string) {
    const color =
      status_expire === 1
        ? Color.GREEN
        : status_expire === 2
        ? Color.YELLOW
        : status_expire === 3
        ? Color.RED
        : "transparent";
    const label =
      status_expire === 1
        ? "VALIDO"
        : status_expire === 2
        ? "IN SCADENZA"
        : status_expire === 3
        ? "SCADUTO"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{dateExp}</p>
          <p>{label}</p>
        </div>
      </div>
    );
  }

  function renderInfo(attestato: any) {
    const label =
      attestato.status === 1
        ? "IN APPROVAZIONE"
        : attestato.status === 2
        ? "PRENOTATO"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {attestato.status === 1 ? (
          <CalendarCog color={Color.BLUE} width={24} height={24} />
        ) : attestato.status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  return (
    <>
      <Ariakit.DisclosureProvider>
        <Disclosure>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "300px",
                display: "flex",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              {icon}
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: Color.LIGHT_BLUE,
                }}
              >
                {name}
              </p>
            </div>
            <p
              style={{
                width: "200px",
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: Color.DARK_GREY,
              }}
            >
              {cf}
            </p>
            <p
              style={{
                width: "100px",
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: Color.DARK_GREY,
              }}
            >
              {headquarter}
            </p>
            <p
              style={{
                width: "150px",
                fontSize: "1rem",
                marginRight: "6rem",
              }}
            >
              {renderScadenza(status, dateExp)}
            </p>
            <p role="button">
              <ChevronDown color={Color.WHITE} height={32} width={32} />
            </p>
          </div>
        </Disclosure>
        <DisclosureContent>
          <SearchInput style={{ width: "90%" }}>
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                name="searchPartecipantiCorso"
                type="text"
                placeholder="Ricerca i partecipanti"
                onChange={(e) => setSearchCorso(e.target.value)}
              />
              <SearchButton>
                <Search color={Color.WHITE} size={16} width={16} />
              </SearchButton>
            </div>
          </SearchInput>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            <TableAttestatiHeader>
              <p>Attestati</p>
              <p
                style={{
                  width: "430px",
                }}
              >
                Corso
              </p>
              <p>Descrizione</p>
              <p>Stato</p>
              <p>Info</p>
              <p></p>
            </TableAttestatiHeader>
            {filterdAttestati.map((a) => (
              <TableAttestatiRow key={`attestato_${a.id}`}>
                <FileDown
                  color={Color.LIGHT_BLUE}
                  width={24}
                  height={24}
                  style={{
                    justifySelf: "start",
                    cursor: "pointer",
                  }}
                />
                <p
                  style={{
                    fontSize: "1rem",
                    width: "430px",
                  }}
                >
                  {a.name}
                </p>
                <p>{a.description}</p>
                <p>{renderScadenza(a.status_expire, a.date_Exp)}</p>
                <p>{renderInfo(a)}</p>
                <p
                  style={{
                    justifySelf: "center",
                  }}
                >
                  <input type="checkbox" />
                </p>
              </TableAttestatiRow>
            ))}
          </div>
          {filterdAttestati?.length > 0 && (
            <OutlineButton
              style={{
                marginLeft: "auto",
                marginTop: "-1rem",
              }}
              onClick={() =>
                goToPage("corso", filterdAttestati[0].name.toLowerCase())
              }
            >
              <CalendarDays color={Color.WHITE} />
              PRENOTA
            </OutlineButton>
          )}
        </DisclosureContent>
      </Ariakit.DisclosureProvider>
    </>
  );
}
