import {CourseDate} from "../../../../types";
import styled from "styled-components";
import {Color} from "../../../../constants/color";
import Label from "../../../label";

interface Props {
    date: CourseDate,
    index: number,
    selected: boolean,
    onClick: (id: number) => void
}

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 24px;
    width: 10rem;
    box-shadow: 0 0 20px 10px ${Color.SHADOW};
    background-color: ${Color.WHITE};
    height: 14rem;
    border-style: solid;
    border-width: thin;
    border-color: ${Color.BLUE};
    padding: 0;
`;

const InfoArrow = styled.div <{
  $selected: boolean;
}>`
    display: ${props => props.$selected ? 'unset' : 'none'};
    content: "";
    position: relative;
    top: 2.2rem;
    margin-top: -31px;
    border: solid 15px transparent;
    border-right-color: #FFF;
    z-index: 1;
    transform: rotate(90deg);
`;

const UpperHalf = styled.div <{
    $selected: boolean
}>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    column-gap: 0.2rem;
    background-color: ${props => props.$selected ? Color.LIGHT_BLUE : Color.WHITE};
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
    height: 50%;
`;

const LowerHalf = styled.div <{
    $selected: boolean
}>`
    background-color: ${props => props.$selected ? Color.WHITE : Color.LIGHT_BLUE};
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    height: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
    justify-content: center;
    div {
        text-align: center;
    }
`;

function getDay(day : number) {
  switch (day) {
    case 2: return 'lun';
    case 6: return 'ven';
    case 7: return 'gio';
    case 9: return 'lun';
    case 16: return 'mer';
    case 17: return 'gio';
    case 20: return 'mer';
    case 24: return 'mar';
    case 30: return 'mar';
  }
}

let monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];


const CourseDateItem = ( {date, selected, onClick, index} : Props) => {
    const handleSelected = () => onClick && onClick(index);
    const day = getDay(date.day)
    return (
        <ItemWrapper onClick={handleSelected}>
            <UpperHalf $selected={selected}>
                <Label text={day ?? "mar"}
                       fontSize={'16px'}
                       textTransform={'uppercase'}
                       color={selected ? Color.WHITE : Color.LIGHT_BLUE}
                       textAlign={'center'}
                />
                <Label text={date.day.toString()}
                       fontSize={'50px'}
                       textTransform={'uppercase'}
                       color={selected ? Color.WHITE : Color.LIGHT_BLUE}
                       textAlign={'center'}
                /><Label text={monthNames.at(date.month -1)!!}
                       fontSize={'18px'}
                       textTransform={'uppercase'}
                       color={selected ? Color.WHITE : Color.LIGHT_BLUE}
                       textAlign={'center'}
                />
            </UpperHalf>
            <LowerHalf $selected={selected}>
                <Label text={date.mode}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       color={selected ? Color.LIGHT_BLUE : Color.WHITE}
                       textAlign={'center'}
                />
                <Label text={date.classroom + ' ' + date.address}
                       fontSize={'10px'}
                       textTransform={'capitalize'}
                       color={selected ? Color.LIGHT_BLUE : Color.WHITE}
                       textAlign={'center'}
                /><Label text={date.duration.toString() + ' ore'}
                         fontSize={'14px'}
                         textTransform={'uppercase'}
                         color={selected ? Color.LIGHT_BLUE : Color.WHITE}
                         textAlign={'center'}
            />
            </LowerHalf>
          <InfoArrow $selected={selected}/>
        </ItemWrapper>
    );
};

export default CourseDateItem