import styled from "styled-components";
import {CourseDate} from "../../../../../types";
import {Color} from "../../../../../constants/color";
import {Icons} from "../../../../../constants/icons";
import Label from "../../../../label";
import Button from "../../../../button/button";
import {BreakpointsQuery} from "../../../../../constants/device";
import {useContext, useState} from "react";
import BookModal from "../../../../modal/book-course";
import {participantsContext} from "../../../../../resources/mock";

interface Props {
    show: boolean,
    date: CourseDate
}

const DetailWrapper = styled.div.attrs({
    className: 'dateDetail',
}) <{
    $show: boolean
}>`
    display: ${props => props.$show ? 'flex' : 'none'};
    flex-direction: column;
    //position: absolute;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    row-gap: 4rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    box-shadow: 0 0 20px 10px ${Color.SHADOW};
    background-color: ${Color.WHITE};
    border-radius: 24px;
    min-height: 14rem;
    width: 52rem;
    @media ${BreakpointsQuery.tablet} {
        width: 50rem;
        bottom: 400px;
    }
    @media (max-width: 1152px) {
        bottom: 0;
    }
    @media (max-height: 860px) {
        bottom: -5rem;
    }
`;

const Row1 = styled.div`
    display: flex;
    max-height: 2rem;
    column-gap: 1.4rem;
    justify-content: start;
    max-width: 40rem;
`;

const Row2 = styled.div`
    display: flex;
    max-height: 2rem;
    column-gap: 1.6rem;
    justify-content: start;
    max-width: 50rem;
    align-items: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    padding-left: 9rem;
`;

const DetailColumn = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
    width: 6.6rem;
    div {
        text-align: left;
        max-width: 15rem;
    }
`;

const SubRow = styled.div`
    display: flex;
`;

const Icon = styled.img`
    width: 1.6rem;
`;

const DateDetail = ( {show, date} : Props ) => {
    const [showModal, setShowModal] = useState(false)
    const courseParticipants = useContext(participantsContext);

    return (
        <DetailWrapper $show={show}>
            <Row1>
                <Icon alt={'data'} src={Icons.COMMONS.CALENDARGREEN}/>
                <DetailColumn>
                    <Label text={'data del corso'}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                    />
                    <Label text={date.detail.firstDate + ' | ' + date.detail.secondDate}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                    />
                </DetailColumn>
                <Icon alt={'duration'} src={Icons.COMMONS.CLOCK}/>
                <DetailColumn>
                    <Label text={'ore di formazione'}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                    />
                    <Label text={date.duration + ' ore'}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                    />
                </DetailColumn>
                <Icon alt={'schedule'} src={Icons.COMMONS.CLOCKALERT}/>
                <SubRow>
                    <DetailColumn>
                        <Label text={'prima giornata'}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'10px'}
                               textAlign={'center'}
                               color={Color.DARK_GREY}
                               width={'6rem'}
                        />
                        <Label text={date.detail.firstHour}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'13px'}
                               textAlign={'center'}
                               color={Color.LIGHT_BLUE}
                        />
                    </DetailColumn>
                    <Label text={'|'}
                           textTransform={'uppercase'}
                           fontWeight={'normal'}
                           fontSize={'30px'}
                           textAlign={'left'}
                           color={Color.LIGHT_BLUE}
                           width={'-webkit-fill-available'}
                    />
                    <DetailColumn>
                        <Label text={'seconda giornata'}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'10px'}
                               textAlign={'center'}
                               color={Color.DARK_GREY}
                               width={'8rem'}
                        />
                        <Label text={date.detail.secondHour}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'13px'}
                               textAlign={'center'}
                               color={Color.LIGHT_BLUE}
                        />
                    </DetailColumn>
                </SubRow>
            </Row1>
            <Row2>
                <Icon alt={'modalita'} src={Icons.COMMONS.NOTEBOOKGREEN}/>
                <DetailColumn>
                    <Label text={'tipo di corso'}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                    />
                    <Label text={date.mode}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                    />
                </DetailColumn>
                <Icon alt={'citta'} src={Icons.COMMONS.MAPPINGREEN}/>
                <DetailColumn>
                    <Label text={'citta'}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}

                    />
                    <Label text={date.city}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                    />
                </DetailColumn>
                <Icon alt={'sede'} src={Icons.COMMONS.BUILDINGGREEN}/>
                <DetailColumn>
                    <Label text={'sede del corso'}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                    />
                    <Label text={date.address}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                           width={'10rem'}
                    />
                </DetailColumn>
                <ButtonContainer>
                    <Button
                        label={'Prenota'}
                        color={Color.BLUE}
                        icon={Icons.COMMONS.CALENDAR}
                        onClick={ () => setShowModal(true)}
                    />
                </ButtonContainer>
            </Row2>
            <BookModal participants={courseParticipants} show={showModal} setShow={() => setShowModal(false)}/>
        </DetailWrapper>
    )
}

export default DateDetail