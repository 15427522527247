import styled from "styled-components";
import Title from "../../components/title";
import {useTranslation} from "react-i18next";
import Dropdown from "../../components/form/dropdown";
import SubmitButton from "../../components/form/submit";
import {Color} from "../../constants/color";
import {BreakpointsQuery} from "../../constants/device";
import Label from "../../components/label";
import CustomSearch from "../../components/search";
import {DropdownOptions} from "../../types";
import EmployeeTable from "../../components/employee-registry/table";

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;
`;

const SearchSection = styled.div`
    display: flex;
    column-gap: 1.4rem;
    background-color: ${Color.BLUE};
    border-radius: 1rem;
    max-width: 64rem;
    min-height: 4.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    align-items: end;
    @media ${BreakpointsQuery.tablet} {
        justify-content: center;
        row-gap: 1rem;
    }
    div {
        color: ${Color.WHITE};
        
    }
`;

const SearchColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const EmployeeRegistry = () => {
    const {t} = useTranslation();
    const options : DropdownOptions[] =
        [
            {
                value: 'value test',
                label: 'label test'
            }
        ]
    return (
        <PageWrapper>
            <Title text={t('employee-registry-title')}
                   fontSize={'20px'}
            />
            <SearchSection>
                <Dropdown name={t('employee-registry-dropdown-state')} placeholder={t('employee-registry-dropdown-state-placeholder')} options={options} />
                <SearchColumn>
                    <Label text={t('cognome')}
                           color={Color.LIGHT_BLUE}
                           textAlign={'left'}
                           textTransform={'uppercase'}
                           fontSize={'16px'}
                           paddingLeft={8}
                           paddingBottom={3}
                    />
                    <CustomSearch onSearch={ () => undefined}
                                  width={'16rem'}
                    />
                </SearchColumn>
                <SearchColumn>
                    <Label text={t('mansione')}
                           color={Color.LIGHT_BLUE}
                           textAlign={'left'}
                           textTransform={'uppercase'}
                           fontSize={'16px'}
                           paddingLeft={8}
                           paddingBottom={3}
                    />
                    <CustomSearch onSearch={ () => undefined}
                                  width={'12rem'}
                                  label={t('employee-registry-dropdown-job-description-placeholder')}
                    />
                </SearchColumn>
                <Dropdown name={t('employee-registry-dropdown-headquarter')} placeholder={t('employee-registry-dropdown-headquarter')} options={options} />
                <SubmitButton label={"Cerca"} form={"cerca"} name={"Cerca"} color={Color.GREEN} />
            </SearchSection>
            <EmployeeTable/>
        </PageWrapper>
    );
};

export default EmployeeRegistry