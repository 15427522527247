import React, { useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import { Certificates } from "../../resources/mock";

import * as Ariakit from "@ariakit/react";
import {
  Bell,
  CalendarCog,
  CalendarDays,
  FileDown,
  ScrollText,
  Search,
} from "lucide-react";
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../form/select";
import { goToPage } from "../../utils/utils";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

const Card = styled.div`
  border-radius: 8px;
  border: 2px solid var(--blue300);
`;

const CardShadowed = styled(Card)`
  border: none;
  box-shadow: 0px 10px 12px 0px var(--shadow800);
`;

const KpiLayout = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color};
`;

const KpiLabel = styled.p<{ $fontSize: string }>`
  font-weight: bold;
  font-size: ${(props) => props.$fontSize};
`;

//Definisco mappa sedi
const sedeAttestati: { [key: string]: string } = {
  "": "TUTTE",
  GENOVA: "GENOVA",
  SAVONA: "SAVONA",
  IMPERIA: "IMPERIA",
};

// Definisco una mappa con lo stato attestati:
// 1 = valido
// 2 = in scadenza
// 3 = scaduto
const statoAttestati: { [key: number]: string } = {
  0: "TUTTI",
  1: "VALIDO",
  2: "IN SCADENZA",
  3: "SCADUTO",
};

const kpiData = {
  certificates: 3,
  expired: 1,
  expired_1m: 1,
};

export default function RicercaPerScadenza() {
  const { t } = useTranslation();

  const [attestati, setAttestati] = React.useState(Certificates);

  const [filters, setFilters] = React.useState({
    corso: "",
    sede: "",
    stato: 0,
  });

  const debouncedFilters = useDebounce(filters, 300);

  useEffect(() => {
    let _attestati = Certificates;
    if (debouncedFilters.corso) {
      _attestati = _attestati.filter((c) =>
        c.name.toLowerCase().includes(debouncedFilters.corso.toLowerCase())
      );
    }

    if (debouncedFilters.sede) {
      _attestati = _attestati.filter((c) =>
        c.headquarter
          .toLowerCase()
          .includes(debouncedFilters.sede.toLowerCase())
      );
    }

    if (debouncedFilters.stato) {
      _attestati = _attestati.filter(
        (c) => c.status_expire === debouncedFilters.stato
      );
    }

    setAttestati(_attestati);
  }, [debouncedFilters]);

  const selectSede = Ariakit.useSelectStore({
    value: filters.sede,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, sede: value }));
    },
  });

  const selectStato = Ariakit.useSelectStore({
    value: `${filters.stato}`,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, stato: +value }));
    },
  });

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchCorso">CORSO</label>
          <div
            style={{
              position: "relative",
              minWidth: "600px",
            }}
          >
            <input
              name="searchCorso"
              type="text"
              placeholder="Ricerca corso"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, corso: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SelectProvider store={selectSede}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              SEDE AZIENDALE
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => sedeAttestati[`${value}`]}
            >
              {Object.entries(sedeAttestati).map(([key, value]) => (
                <SelectItem key={`sedeAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
        <SelectProvider store={selectStato}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              STATO
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => statoAttestati[+value]}
            >
              {Object.entries(statoAttestati).map(([key, value]) => (
                <SelectItem key={`statoAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
      </SearchContainer>
      <CardShadowed
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "1250px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr 1fr 1fr",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              height: "100%",
              color: "var(--blue500)",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <ScrollText color={Color.BLUE} width={48} height={48} />
              <p
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "bolder",
                  lineHeight: "1em",
                }}
              >
                {t("dashboard-certificates")}
              </p>
            </div>
            <p
              style={{
                fontSize: "1.3em",
                fontWeight: "bold",
                textWrap: "balance",
                lineHeight: "1.1em",
              }}
            >
              {t("dashboard-description")}
            </p>
          </div>
          {/* KPI SCADUTI */}
          <KpiLayout $color="var(--red500)">
            <Bell color={Color.RED} width={32} height={32} />
            <KpiLabel $fontSize="1.2rem">{t("dashboard-expired")}</KpiLabel>
            <KpiLabel $fontSize="1.5rem">{kpiData.expired}</KpiLabel>
          </KpiLayout>
          {/* KPI IN SCADENZA */}
          <KpiLayout $color="var(--orange500)">
            <Bell color={Color.ORANGE} width={32} height={32} />
            <KpiLabel $fontSize="1.2rem">{t("dashboard-expiring")}</KpiLabel>
            <KpiLabel $fontSize="1.5rem">{kpiData.expired_1m}</KpiLabel>
          </KpiLayout>
          {/* KPI VALIDI */}
          <KpiLayout $color="var(--green500)">
            <Bell color={Color.GREEN} width={32} height={32} />
            <KpiLabel $fontSize="1.1rem">{t("dashboard-valid")}</KpiLabel>
            <KpiLabel $fontSize="1.5rem">
              {kpiData.certificates - kpiData.expired}
            </KpiLabel>
          </KpiLayout>
        </div>
      </CardShadowed>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        <TableCertificates attestati={attestati} />
      </div>
    </Layout>
  );
}

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: 100px auto 300px 150px 150px 150px 60px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0;
  padding: 0.3em 1.5em;
  border-radius: 8px;
  font-size: 1.6em;
  font-weight: bold;
  cursor: pointer;
`;

function TableCertificates({ attestati }: { attestati: any[] }) {
  const [filterdAttestati, setFilteredAttestati] = React.useState(attestati);
  const [searchPartecipante, setSearchPartecipante] = React.useState("");

  const debauncedSearchPartecipante = useDebounce(searchPartecipante, 300);

  useEffect(() => {
    if (debauncedSearchPartecipante) {
      setFilteredAttestati(
        attestati.filter((a) =>
          a.discente
            .toLowerCase()
            .includes(debauncedSearchPartecipante.toLowerCase())
        )
      );
    } else {
      setFilteredAttestati(attestati);
    }
  }, [debauncedSearchPartecipante, attestati]);

  function renderScadenza(attestato: any) {
    const color =
      attestato.status_expire === 1
        ? Color.GREEN
        : attestato.status_expire === 2
        ? Color.YELLOW
        : attestato.status_expire === 3
        ? Color.RED
        : "transparent";
    const label =
      attestato.status_expire === 1
        ? "VALIDO"
        : attestato.status_expire === 2
        ? "IN SCADENZA"
        : attestato.status_expire === 3
        ? "SCADUTO"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{attestato.date_exp}</p>
          <p>{label}</p>
        </div>
      </div>
    );
  }

  function renderInfo(attestato: any) {
    const label =
      attestato.status === 1
        ? "IN APPROVAZIONE"
        : attestato.status === 2
        ? "PRENOTATO"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {attestato.status === 1 ? (
          <CalendarCog color={Color.BLUE} width={24} height={24} />
        ) : attestato.status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  return (
    <>
      <SearchInput style={{ width: "80%" }}>
        <div
          style={{
            position: "relative",
          }}
        >
          <input
            name="searchPartecipantiCorso"
            type="text"
            placeholder="Ricerca i partecipanti"
            onChange={(e) => setSearchPartecipante(e.target.value)}
          />
          <SearchButton>
            <Search color={Color.WHITE} size={16} width={16} />
          </SearchButton>
        </div>
      </SearchInput>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <TableAttestatiHeader>
          <p>Attestato</p>
          <p>Nome e cognome</p>
          <p>Corso</p>
          <p>Sede aziendale</p>
          <p>Stato</p>
          <p>Info</p>
        </TableAttestatiHeader>
        {filterdAttestati.map((a) => (
          <TableAttestatiRow key={`attestato_${a.id}`}>
            <FileDown
              color={Color.LIGHT_BLUE}
              width={24}
              height={24}
              style={{
                justifySelf: "start",
                cursor: "pointer",
              }}
            />
            <p>{a.discente}</p>
            <p>{a.name}</p>
            <p>{a.headquarter}</p>
            <p>{renderScadenza(a)}</p>
            <p>{renderInfo(a)}</p>
            <p
              style={{
                justifySelf: "center",
              }}
            >
              <input type="checkbox" />
            </p>
          </TableAttestatiRow>
        ))}
      </div>
      {filterdAttestati?.length > 0 && (
        <OutlineButton
          style={{
            marginLeft: "auto",
            marginTop: "-1rem",
          }}
          onClick={() =>
            goToPage("corso", filterdAttestati[0].name.toLowerCase())
          }
        >
          <CalendarDays color={Color.WHITE} />
          PRENOTA
        </OutlineButton>
      )}
    </>
  );
}
