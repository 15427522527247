import styled from "styled-components";
import {Color} from "../../constants/color";
import {ButtonProps} from "../../types";

const StyledButton = styled.button<{$color: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.4rem;
    position: relative;
    margin-top: auto;
    width: max-content;
    height: 32px;
    border-radius: 18px;
    border-style: hidden;
    background-color: ${$color => $color.$color ?? Color.LIGHT_BLUE};
    color: ${Color.WHITE};
    padding: 0.4rem 1rem;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Avenir Next", sans-serif;
`;

const ButtonIcon = styled.img`
    width: 1.2rem;
`;

function Button ( {icon, label, color, onClick} : ButtonProps ) {
    return (
        <StyledButton type={'button'} $color={color!!} onClick={onClick}>
            <ButtonIcon alt={label} src={icon}/>
            {label}
        </StyledButton>
    );
}

export default Button