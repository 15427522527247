import styled from "styled-components";
import Title from "../../components/title";
import {useTranslation} from "react-i18next";
import Label from "../../components/label";
import {Color} from "../../constants/color";
import {DropdownOptions} from "../../types";
import CourseCarousel from "../../components/book-course/carousel";
import Dropdown from "../../components/form/dropdown";
import SubmitButton from "../../components/form/submit";
import {BreakpointsQuery} from "../../constants/device";
import CourseList from "../../components/book-course/vertical-list";

// mock course images
import {useContext} from "react";
import {courses} from "../../resources/mock";


const BookCourseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
`;

const DropdownSection = styled.div`
    display: flex;
    column-gap: 0.8rem;
    background-color: ${Color.BLUE};
    padding: 1rem;
    border-radius: 1rem;
    align-items: flex-end;
    @media ${BreakpointsQuery.tablet} {
        justify-content: center;
        row-gap: 1rem;
    }
    div {
        color: ${Color.WHITE};
        
    }
`;

const options : DropdownOptions[] =
    [
        {
            value: 'value test',
            label: 'label test'
        }
    ]

/*const coursesArray : CourseCarouselItem[] = [
    {
        image: Antincendio,
        date: 'lun 30 settembre',
        name: 'aggiornamento addetti antincendio livello 3 (3 agg)',
        mode: 'presenza',
        headquarter: 'genova',
        address: 'polo struppa via gualco 58 (ge)'
    },
    {
        image: Ple,
        date: 'mer 16 ottobre',
        name: 'AGGIORNAMENTO PLE (PIATTAFORME AEREE)',
        mode: 'presenza',
        headquarter: 'genova',
        address: 'area ple Bolzaneto via san quirico 33R (Ge)'
    },
    {
        image: PrimoSoccorso,
        date: 'mar 24 settembre',
        name: 'primo soccorso gruppi B-C',
        mode: 'presenza',
        headquarter: 'genova',
        address: 'Aula Giano via al molo giano (GE)'
    },
    {
        image: Rspp,
        date: 'gio 7 novembre',
        name: 'RSPP DATORE DI LAVORO RISCHIO BASSO',
        mode: 'presenza',
        headquarter: 'genova',
        address: 'polo struppa via gualco 58 (ge)'
    },
    {
        image: Spazi,
        date: 'ven 6 dicembre',
        name: 'SPAZI CONFINATI - 12 ORE',
        mode: 'presenza',
        headquarter: 'genova',
        address: 'polo struppa via gualco 58 (ge)'
    },
]*/


const BookCourse = () => {
    const { t } = useTranslation();
    const coursesArray = useContext(courses)
    return (
        <BookCourseWrapper>
            <Title text={t('book-course-title')}/>
            <Label
                text={t('book-course-featured-courses')}
                color={Color.GREEN}
                textAlign={'center'}
                fontSize={'20px'}
                fontWeight={'bold'}
                textTransform={'uppercase'}
            />
            <CourseCarousel courses={coursesArray}/>
            <DropdownSection>
                <Dropdown name={t('book-course-search-keyword')} placeholder={t('book-course-search-placeholder-keyword')} options={options} />
                <Dropdown name={t('book-course-search-headquarter')} placeholder={t('book-course-search-placeholder-headquarter')} options={options} />
                <Dropdown name={t('book-course-search-category')} placeholder={t('book-course-search-placeholder-category')} options={options} />
                <Dropdown name={t('book-course-search-month')} placeholder={t('book-course-search-placeholder-month')} options={options} />
                <SubmitButton label={"Cerca"} form={"cerca"} name={"Cerca"} color={Color.GREEN} />
            </DropdownSection>
            <CourseList courses={coursesArray}/>
        </BookCourseWrapper>
    );
};

export default BookCourse