import styled from "styled-components";
import Title from "../../components/title";
import { useTranslation } from "react-i18next";
import Dropdown from "../../components/form/dropdown";
import {DropdownOptions} from "../../types";
import SubmitButton from "../../components/form/submit";
import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import Course from "../../components/reserved-courses/course";
import {Icons} from "../../constants/icons";
import {coursesPage, participantsContext, selectedParticipant} from "../../resources/mock";
import {useContext} from "react";



const ReservedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  align-items: center;
  justify-content: baseline;
`;

const DropdownSection = styled.div`
  display: flex;
  column-gap: 0.8rem;
  align-items: flex-end;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    row-gap: 1rem;
  }
`;

const ReservedCourses = () => {
  const { t } = useTranslation();
    const mockCourse = useContext(coursesPage)

    const mockedIcons = [
        Icons.COURSE_TYPES.ANTINCENDIO,
        Icons.COURSE_TYPES.PIATTAFORME,
        Icons.COURSE_TYPES.PRIMOSOCCORSO,
        Icons.COURSE_TYPES.RISCHIO,
        Icons.COURSE_TYPES.SPAZI,
    ]

    const options : DropdownOptions[] =
        [
            {
                value: 'value test',
                label: 'label test'
            }
        ]
    const participant = useContext(selectedParticipant)
    const courseMockParticipants = useContext(participantsContext);
    const courseParticipants = courseMockParticipants.filter(el => participant.has(el.lastName + ' ' + el.firstName))

  return (
    <ReservedWrapper>
      <Title text={t("reserved-courses-title")} />
      <DropdownSection>
        <Dropdown
          name={t("reserved-courses-search-keyword")}
          placeholder={t("reserved-courses-search-placeholder-keyword")}
          options={options}
        />
        <Dropdown
          name={t("reserved-courses-search-lecture-mode")}
          placeholder={t("reserved-courses-search-placeholder-lecture-mode")}
          options={options}
        />
        <Dropdown
          name={t("reserved-courses-search-month")}
          placeholder={t("reserved-courses-search-placeholder-month")}
          options={options}
        />
        <Dropdown
          name={t("reserved-courses-search-headquarter")}
          placeholder={t("reserved-courses-search-placeholder-headquarter")}
          options={options}
        />
        <SubmitButton
          label={"Cerca"}
          form={"cerca"}
          name={"Cerca"}
          color={Color.GREEN}
        />
      </DropdownSection>
        {mockCourse.map((singleCourse, index) => {
            return (
                <Course
                    type={mockedIcons[index]}
                    courseName={singleCourse.name}
                    lectureMode={Icons.LECTURE_MODES.PRESENCE}
                    courseDate={singleCourse.availableDates.at(0)!!.day.toString() + '/' + singleCourse.availableDates.at(0)!!.month.toString()}
                    courseHours={singleCourse.availableDates.at(0)!!.detail.firstHour + ' ' + singleCourse.availableDates.at(0)!!.detail.secondHour}
                    city={singleCourse.availableDates.at(0)!!.city}
                    address={singleCourse.availableDates.at(0)!!.address}
                    participants={courseParticipants}
                    key={index}
                />
            );
        })}
    </ReservedWrapper>
  );
};

export default ReservedCourses;
