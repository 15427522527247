import {
  CourseCarouselItem,
  CoursePage,
  CourseParticipant,
  Employee,
} from "../../types";
import { createContext } from "react";
import Antincendio from "../course-image/antincendio.jpg";
import Ple from "../course-image/ple.jpg";
import PrimoSoccorso from "../course-image/primo-soccorso.jpg";
import Rspp from "../course-image/rspp.jpg";
import Spazi from "../course-image/spazi.jpg";

const courseParticipants: CourseParticipant[] = [
  {
    firstName: "Mario",
    lastName: "Rossi",
    headquarter: "Genova",
    jobDescription: "Operaio",
  },
  {
    firstName: "Bianco",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Verde",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Blu",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Giallo",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Viola",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Grigio",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
];

const employees: Employee[] = [
  {
    state: "assunto",
    firstName: "mario",
    lastName: "rossi",
    CF: "RSSMRA80C06F205Q",
    jobDescription: "Operaio",
    headquarterJob: "Genova",
    startDate: "04/01/2012",
    endDate: "04/01/2025",
    email: "rossimario@company.com",
    phone: "000000000",
  },
  {
    state: "assunto",
    firstName: "Steven",
    lastName: "Bianco",
    CF: "RSSMRA80C06F205Q",
    jobDescription: "Impiegato Tecnico",
    headquarterJob: "Genova",
    startDate: "04/01/2012",
    endDate: "04/01/2025",
    email: "biancosteven@company.com",
    phone: "000000000",
  },
  {
    state: "assunto",
    firstName: "Steven",
    lastName: "Verde",
    CF: "RSSMRA80C06F205Q",
    jobDescription: "Impiegato Tecnico",
    headquarterJob: "Genova",
    startDate: "04/01/2012",
    endDate: "04/01/2025",
    email: "verdesteven@company.com",
    phone: "000000000",
  },
  {
    state: "assunto",
    firstName: "Steven",
    lastName: "Blu",
    CF: "BNCSVN91L12F205K",
    jobDescription: "Impiegato Tecnico",
    headquarterJob: "Genova",
    startDate: "04/01/2012",
    endDate: "04/01/2025",
    email: "blusteven@company.com",
    phone: "000000000",
  },
  {
    state: "assunto",
    firstName: "Steven",
    lastName: "Giallo",
    CF: "GLLSVN90R02F205Y",
    jobDescription: "Impiegato Tecnico",
    headquarterJob: "Genova",
    startDate: "04/01/2012",
    endDate: "04/01/2025",
    email: "giallosteven@company.com",
    phone: "000000000",
  },
  {
    state: "assunto",
    firstName: "Steven",
    lastName: "Viola",
    CF: "VLISVN86L05F205Q",
    jobDescription: "Impiegato Tecnico",
    headquarterJob: "Genova",
    startDate: "04/01/2012",
    endDate: "04/01/2025",
    email: "violasteven@company.com",
    phone: "000000000",
  },
  {
    state: "assunto",
    firstName: "Steven",
    lastName: "Grigio",
    CF: "GRGSVN93B15F205T",
    jobDescription: "Impiegato Tecnico",
    headquarterJob: "Genova",
    startDate: "04/01/2012",
    endDate: "04/01/2025",
    email: "grigiosteven@company.com",
    phone: "000000000",
  },
];

/*
status-expire: 1 valido, 2 in scadenza, 3 scaduto
status: 1 in approvazione, 2 prenotato, 3 completato
*/

export const CourseCertificates = [
  {
    course: "PRIMO SOCCORSO GRUPPI B-C",
    certificates: [
      {
        id: 1,
        name: "Mario Rossi",
        headquarter: "Genova",
        hours: 4,
        date_exp: "11/05/2023",
        status_expire: 3,
        status: 3,
      },
      {
        id: 2,
        name: "Steven Bianco",
        headquarter: "Genova",
        hours: 4,
        date_exp: "11/02/2024",
        status_expire: 1,
        status: 3,
      },
      {
        id: 3,
        name: "Steven Verde",
        headquarter: "Genova",
        hours: 4,
        date_exp: "20/10/2023",
        status_expire: 2,
        status: 2,
      },
    ],
  },
  {
    course: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    certificates: [
      {
        id: 4,
        name: "Steven Blu",
        headquarter: "Genova",
        hours: 8,
        date_exp: "11/05/2023",
        status_expire: 3,
        status: 3,
      },
      {
        id: 5,
        name: "Steven Giallo",
        headquarter: "Savona",
        hours: 4,
        date_exp: "11/02/2024",
        status_expire: 1,
        status: 3,
      },
      {
        id: 6,
        name: "Steven Viola",
        headquarter: "Genova",
        hours: 4,
        date_exp: "20/01/2024",
        status_expire: 2,
        status: 2,
      },
    ],
  },
];

export const CourseEmployees = [
  {
    name: "Mario Rossi",
    cf: "MRORSS80C06F205Q",
    headquarter: "GENOVA",
    status_expire: 3,
    date_exp: "11/05/2023",
    certificates: [
      {
        id: 1,
        name: "PRIMO SOCCORSO GRUPPI B-C",
        description: "Livello 1",
        date_exp: "11/05/2023",
        status_expire: 3,
        status: 3,
      },
      {
        id: 2,
        name: "Antincendio",
        description: "Generale",
        date_exp: "11/02/2024",
        status_expire: 1,
        status: 3,
      },
      {
        id: 3,
        name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        description: "Specifica",
        date_exp: "01/02/2023",
        status_expire: 2,
        status: 2,
      },
    ],
  },
  {
    name: "Steven Giallo",
    cf: "STVGLL90R02F205Y",
    headquarter: "IMPERIA",
    status_expire: 1,
    date_exp: "08/06/2024",
    certificates: [
      {
        id: 4,
        name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        description: "Livello 1",
        date_exp: "08/06/2022",
        status_expire: 3,
        status: 3,
      },
    ],
  },
  {
    name: "Steven Verde",
    cf: "STVVRD90R02F205Y",
    headquarter: "SAVONA",
    status_expire: 2,
    date_exp: "23/04/2022",
    certificates: [
      {
        id: 5,
        name: "PRIMO SOCCORSO GRUPPI B-C",
        description: "Livello 1",
        date_exp: "08/06/2021",
        status_expire: 3,
        status: 3,
      },
      {
        id: 6,
        name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        description: "Livello 1",
        date_exp: "23/04/2022",
        status_expire: 2,
        status: 2,
      },
    ],
  },
];

export const Certificates = [
  {
    id: 1,
    name: "PRIMO SOCCORSO GRUPPI B-C",
    discente: "Mario Rossi",
    headquarter: "GENOVA",
    status_expire: 3,
    date_exp: "11/05/2023",
    status: 2,
  },
  {
    id: 2,
    name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    discente: "Steven Blu",
    headquarter: "IMPERIA",
    date_exp: "11/02/2024",
    status_expire: 1,
    status: 3,
  },
  {
    id: 3,
    name: "Antincendio",
    discente: "Steven Giallo",
    headquarter: "SAVONA",
    status_expire: 2,
    date_exp: "23/04/2022",
    status: 1,
  },
  {
    id: 4,
    name: "PRIMO SOCCORSO GRUPPI B-C",
    discente: "Steven Viola",
    headquarter: "Genova",
    status_expire: 1,
    date_exp: "23/04/2024",
    status: 3,
  },
];

export const participantsContext = createContext(courseParticipants);
export const selectedParticipant = createContext(new Set<string>());
export const employeeData = createContext(employees);

export const coursesArray: CourseCarouselItem[] = [
  {
    image: Antincendio,
    date: "lun 30 settembre",
    name: "aggiornamento addetti antincendio livello 3 (3 agg)",
    mode: "presenza",
    headquarter: "genova",
    address: "polo struppa via gualco 58 (ge)",
  },
  {
    image: Ple,
    date: "mer 16 ottobre",
    name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    mode: "presenza",
    headquarter: "genova",
    address: "area ple Bolzaneto via san quirico 33R (Ge)",
  },
  {
    image: PrimoSoccorso,
    date: "mar 24 settembre",
    name: "primo soccorso gruppi B-C",
    mode: "presenza",
    headquarter: "genova",
    address: "Aula Giano via al molo giano (GE)",
  },
  {
    image: Rspp,
    date: "gio 7 novembre",
    name: "RSPP DATORE DI LAVORO RISCHIO BASSO",
    mode: "presenza",
    headquarter: "genova",
    address: "polo struppa via gualco 58 (ge)",
  },
  {
    image: Spazi,
    date: "ven 6 dicembre",
    name: "SPAZI CONFINATI - 12 ORE",
    mode: "presenza",
    headquarter: "genova",
    address: "polo struppa via gualco 58 (ge)",
  },
];

export const courses = createContext(coursesArray);

const mockCourse: CoursePage[] = [
  {
    name: "aggiornamento addetti antincendio livello 3 (3 agg)",
    availableDates: [
      {
        day: 2,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "2 sett",
          secondDate: "3 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 17,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "17 ott",
          secondDate: "18 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 20,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "20 nov",
          secondDate: "21 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    availableDates: [
      {
        day: 2,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "2 sett",
          secondDate: "3 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 20,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "20 nov",
          secondDate: "21 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "primo soccorso gruppi B-C",
    availableDates: [
      {
        day: 24,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "24 sett",
          secondDate: "25 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 20,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "20 nov",
          secondDate: "21 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "RSPP DATORE DI LAVORO RISCHIO BASSO",
    availableDates: [
      {
        day: 24,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "24 sett",
          secondDate: "25 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 7,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "7 nov",
          secondDate: "8 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "SPAZI CONFINATI - 12 ORE",
    availableDates: [
      {
        day: 24,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "24 sett",
          secondDate: "25 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 7,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "7 nov",
          secondDate: "8 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 6,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "6 dic",
          secondDate: "6 dic",
          firstHour: "09:00-13:00",
          secondHour: "14:00-18:00",
        },
      },
    ],
  },
];

export const coursesPage = createContext(mockCourse);
