import axios from "axios";
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {useCookies} from "react-cookie";
import {COOKIE_MAX_AGE} from "../utils/config";

type Props = {
    children?: ReactNode
}

type IAuthContext = {
    token: string | null;
    setToken: (newToken: string) => void
}

const initAuth = {
    token: '',
    setToken: () => {}
}

const AuthContext = createContext<IAuthContext>(initAuth);

const AuthProvider = ({ children } : Props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    // State to hold the authentication token
    const [token, setToken_] = useState(cookies['token']);

    // Function to set the authentication token
    const setToken = (newToken : string) => {
        if (newToken === 'logout') {
            setToken_(null)
        } else {
            setToken_(newToken);
        }
    };

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            setCookie('token', token, {
                secure: true,
                maxAge: Number(COOKIE_MAX_AGE) * 24 * 60 * 60,
                path: '/',
            });
        } else {
            delete axios.defaults.headers.common["Authorization"];
            removeCookie('token')
        }
    }, [removeCookie, setCookie, token]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            setToken,
        }),
        [token]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;