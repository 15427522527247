import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {EmailAddressProps} from "../../../types";
import {Color} from "../../../constants/color";

const StyledMail = styled(NavLink)`
    text-decoration: none;
    color: ${Color.LIGHT_BLUE};
    font-family: "Avenir Next", sans-serif;
    font-weight: bold;
    align-items: center;
`;

const EmailAddress = ({ address } : EmailAddressProps) => {
    return (
        <StyledMail to={`mailto:${address}`}>{address}</StyledMail>
    )
}

export default EmailAddress;