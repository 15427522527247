export const Color = {
  WHITE: "var(--white)",
  CERAMIC: "var(--ceramic500)",
  LIGHTER_GREY: "var(--grey100)",
  LIGHT_GREY: "var(--grey300)",
  GREY: "var(--grey500)",
  DARK_GREY: "var(--grey800)",
  BLACK: "var(--black)",

  LIGHTER_BLUE: "var(--blue100)",
  LIGHT_BLUE: "var(--blue300)",
  BLUE: "var(--blue500)",
  DARK_BLUE: "var(--blue800)",

  LIGHT_GREEN: "var(--green300)",
  GREEN: "var(--green500)",

  LIGHT_RED: "var(--red300)",
  RED: "var(--red500)",
  ORANGE: "var(--orange500)",

  LIGHT_YELLOW: "var(--yellow300)",
  YELLOW: "var(--yellow500)",

  SHADOW: "var(--shadow500)",
  PINK: '#F2BAB9',
};
