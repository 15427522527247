import React, { useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";

import SubmitButton from "../form/submit";
import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import { CourseCertificates } from "../../resources/mock";

import * as Ariakit from "@ariakit/react";
import {
  Bell,
  CalendarCog,
  CalendarDays,
  FileDown,
  HardHat,
  Search,
} from "lucide-react";
import { goToPage } from "../../utils/utils";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

export default function RicercaPerCorso() {
  const [attestati, setAttestati] = React.useState(CourseCertificates);

  const [filters, setFilters] = React.useState({
    corso: "",
    anno: "",
    sede: "",
  });

  const debouncedFilters = useDebounce(filters, 300);

  useEffect(() => {
    let _attestati = CourseCertificates;
    if (debouncedFilters.corso) {
      // devo filtrare i corsi il cui nome contiene la stringa cercata
      _attestati = _attestati.filter((c) =>
        c.course.toLowerCase().includes(debouncedFilters.corso.toLowerCase())
      );
    }

    if (debouncedFilters.anno && debouncedFilters.anno.length === 4) {
      _attestati = _attestati.map((c) => ({
        ...c,
        certificates: c.certificates.filter((a) =>
          a.date_exp.includes(`/${debouncedFilters.anno}`)
        ),
      }));
    }

    // devo filtrare i certificates contenuti in ogni corso sul campo headquarter
    if (debouncedFilters.sede) {
      _attestati = _attestati.map((c) => ({
        ...c,
        certificates: c.certificates.filter((a) =>
          a.headquarter
            .toLowerCase()
            .includes(debouncedFilters.sede.toLowerCase())
        ),
      }));
    }
    setAttestati(_attestati);
  }, [debouncedFilters]);

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchCorso">CORSO</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchCorso"
              type="text"
              placeholder="Ricerca per corso"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, corso: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SearchInput>
          <label htmlFor="searchAnno">ANNO</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchAnno"
              type="text"
              placeholder="Ricerca per anno"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, anno: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SearchInput>
          <label htmlFor="searchSede">SEDE AZIENDALE</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchSede"
              type="text"
              placeholder="Ricerca per sede"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, sede: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SubmitButton
          label={"Cerca"}
          form={"cerca"}
          name={"Cerca"}
          color={Color.GREEN}
        />
      </SearchContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        {attestati.map((c) => (
          <div
            style={{
              width: "100%",
              filter: "drop-shadow(4px -4px 5px var(--shadow800))",
            }}
          >
            <CourseAccordion
              icon={<HardHat color={Color.LIGHT_BLUE} size={48} />}
              key={c.course}
              title={c.course}
              attestati={c.certificates}
              triggerLabel="PARTECIPANTI"
            />
          </div>
        ))}
      </div>
    </Layout>
  );
}

const Disclosure = styled(Ariakit.Disclosure)`
  border-radius: 0.8rem;
  background-color: ${Color.WHITE};
  width: 100%;
  position: relative;
  overflow: hidden;
  border: none;
  padding: 0.5rem 1.5rem;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 1rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  & p[role='button'] {
    position: absolute;
    right: 0;
    top: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: ${Color.GREEN};
    color: ${Color.WHITE};
    font-weight: bold;
`;

const DisclosureContent = styled(Ariakit.DisclosureContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-height: 24rem;
  width: calc(100% - 0.8rem);
  margin: -8px auto 0 auto;

  background-color: ${Color.CERAMIC};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding: 1.6rem 2rem;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
`;

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: 100px auto 150px 200px 150px 150px 60px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0;
  padding: 0.3em 1.5em;
  border-radius: 8px;
  font-size: 1.6em;
  font-weight: bold;
  cursor: pointer;
`;

function CourseAccordion({
  icon,
  title,
  triggerLabel,
  attestati,
}: {
  icon?: React.ReactNode;
  title: string;
  triggerLabel: string;
  attestati: any[];
}) {
  const [filterdAttestati, setFilteredAttestati] = React.useState(attestati);
  const [searchPartecipante, setSearchPartecipante] = React.useState("");

  const debauncedSearchPartecipante = useDebounce(searchPartecipante, 300);

  useEffect(() => {
    if (debauncedSearchPartecipante) {
      setFilteredAttestati(
        attestati.filter((a) =>
          a.name
            .toLowerCase()
            .includes(debauncedSearchPartecipante.toLowerCase())
        )
      );
    } else {
      setFilteredAttestati(attestati);
    }
  }, [debauncedSearchPartecipante, attestati]);

  function renderScadenza(attestato: any) {
    const color =
      attestato.status_expire === 1
        ? Color.GREEN
        : attestato.status_expire === 2
        ? Color.YELLOW
        : attestato.status_expire === 3
        ? Color.RED
        : "transparent";
    const label =
      attestato.status_expire === 1
        ? "VALIDO"
        : attestato.status_expire === 2
        ? "IN SCADENZA"
        : attestato.status_expire === 3
        ? "SCADUTO"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{attestato.date_exp}</p>
          <p>{label}</p>
        </div>
      </div>
    );
  }

  function renderInfo(attestato: any) {
    const label =
      attestato.status === 1
        ? "IN APPROVAZIONE"
        : attestato.status === 2
        ? "PRENOTATO"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {attestato.status === 1 ? (
          <CalendarCog color={Color.BLUE} width={24} height={24} />
        ) : attestato.status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  return (
    <Ariakit.DisclosureProvider>
      <Disclosure>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: Color.LIGHT_BLUE,
            }}
          >
            {icon}
            <p>{title}</p>
          </div>
          <p role="button">{triggerLabel}</p>
        </div>
      </Disclosure>
      <DisclosureContent>
        <SearchInput style={{ width: "90%" }}>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchPartecipantiCorso"
              type="text"
              placeholder="Ricerca i partecipanti"
              onChange={(e) => setSearchPartecipante(e.target.value)}
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          <TableAttestatiHeader>
            <p>Attestato</p>
            <p>Nome e cognome</p>
            <p>Sede aziendale</p>
            <p>Ore di formazione</p>
            <p>Stato</p>
            <p>Info</p>
          </TableAttestatiHeader>
          {filterdAttestati.map((a) => (
            <TableAttestatiRow key={`attestato_${a.id}`}>
              <FileDown
                color={Color.LIGHT_BLUE}
                width={24}
                height={24}
                style={{
                  justifySelf: "start",
                  cursor: "pointer",
                }}
              />
              <p
                style={{
                  fontSize: "1rem",
                }}
              >
                {a.name}
              </p>
              <p>{a.headquarter}</p>
              <p>{a.hours} ore</p>
              <p>{renderScadenza(a)}</p>
              <p>{renderInfo(a)}</p>
              <p
                style={{
                  justifySelf: "center",
                }}
              >
                <input type="checkbox" />
              </p>
            </TableAttestatiRow>
          ))}
        </div>
        {filterdAttestati?.length > 0 && (
          <OutlineButton
            style={{
              marginLeft: "auto",
              marginTop: "-1rem",
            }}
            onClick={() => goToPage("corso", title.toLowerCase())}
          >
            <CalendarDays color={Color.WHITE} />
            PRENOTA
          </OutlineButton>
        )}
      </DisclosureContent>
    </Ariakit.DisclosureProvider>
  );
}
