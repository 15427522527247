import {AddressProps} from "../types";

export const PHONE_NUMBER1 = "+39 329 303 9835"
export const PHONE_NUMBER2 = "+39 329 303 9835"
export const EMAIL_ADDRES = "contatto@sempreformati.it"
export const ADDRESS: AddressProps = {
    label: "Via Corsica 2/17 16128 Genova GE",
    coordinate: {
        latitude: "44.4021318",
        longitude: "44.4021318"
    }
}