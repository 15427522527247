import React from "react";
import styled from "styled-components";
import { BreakpointsQuery } from "../../constants/device";
import {useTranslation} from "react-i18next";
import PhoneNumber from "../info/phone-number";
import {ADDRESS, EMAIL_ADDRES, PHONE_NUMBER1, PHONE_NUMBER2} from "../../constants/info";
import EmailAddress from "../info/email";
import Address from "../info/address";
import {NavLink} from "react-router-dom";
import Label from "../label";
import {Color} from "../../constants/color";
import {Icons} from "../../constants/icons";
import CharacterSeparator from "../separator/character";

const FooContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${Color.WHITE};
    top: calc(45vh - 11.5rem);
    //z-index: 100;
    flex-direction: column;
    height: 11.5rem;
    column-gap: 1.2rem;
    border-top: outset 5px ${Color.LIGHT_BLUE};
    width: 100%;
    text-align: center;
    margin-top: 40px;
    @media ${BreakpointsQuery.tablet} {
        width: auto;
        column-gap: 0.8rem;
    }
    @media (max-height: 860px) {
        top: calc(80vh - 11.5rem);
    }
`;

const Row1 = styled.div`
    display: flex;
    flex-direction: row;
`;

const Column1 = styled.div`
    flex: 0.8 1 0;
`;

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    flex: 5 1 0;
`;

const Column3 = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

const SubRow1 = styled.div`
    text-align: left;
`;

const SubRow2 = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
    @media ${BreakpointsQuery.tablet} {
        column-gap: 0.5rem;
        font-size: 15px;
    }
`;

const SubRow3 = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;

`;

const SubCol1 = styled.div`
    display: flex;
    flex-direction: column;

`;

const Row2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 2rem;
`;

const FooLogo = styled.img`
    width: 60%;
    @media ${BreakpointsQuery.tablet} {
        width: 60%;
    }
`;

const InfoHeader = styled.div`
    color: ${Color.LIGHT_BLUE};
    font-family: "Avenir Next", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
`;

const PhoneImage = styled.img`
    max-width: 15%;
`;

const EmailImage = styled.img`
    max-width: 10%;
`;

const AddressImage = styled.img`
    max-width: 10%;
`;

const SocialIconContainer = styled.div`
    width: 100%;
`;

const SocialIconImage = styled.img`
`;

const SocialIconLink = styled(NavLink)`
    width: 100%;
`;

const Footer = () => {
    const { t } = useTranslation();
    return (
        <FooContainer>
            <Row1>
                <Column1>
                    <FooLogo alt={'footer logo'} src={Icons.LOGO.FOOTER}/>
                </Column1>
                <Column2>
                    <SubRow1>
                        <InfoHeader>{t('footer-info-contact')}</InfoHeader>
                    </SubRow1>
                    <SubRow2>
                        <SubRow3>
                            <PhoneImage src={Icons.CONTACTS.PHONE}/>
                            <SubCol1>
                                <PhoneNumber phone={PHONE_NUMBER1}/>
                                <PhoneNumber phone={PHONE_NUMBER2}/>
                            </SubCol1>
                        </SubRow3>
                        <SubRow3>
                            <EmailImage src={Icons.CONTACTS.EMAIL}/>
                            <EmailAddress address={EMAIL_ADDRES}/>
                        </SubRow3>
                        <SubRow3>
                            <AddressImage src={Icons.CONTACTS.ADDRESS}/>
                            <Address label={ADDRESS.label} coordinate={ADDRESS.coordinate}/>
                        </SubRow3>
                        <Column3>
                            <SubRow1>
                                <InfoHeader>{t('footer-info-social')}</InfoHeader>
                            </SubRow1>
                            <SubRow3>
                                <SocialIconContainer>
                                    <SocialIconImage src={Icons.SOCIAL.FACEBOOK}/>
                                    <SocialIconLink to={"#"}/>
                                </SocialIconContainer>
                                <SocialIconContainer>
                                    <SocialIconImage src={Icons.SOCIAL.INSTAGRAM}/>
                                    <SocialIconLink to={"#"}/>
                                </SocialIconContainer>
                                <SocialIconContainer>
                                    <SocialIconImage src={Icons.SOCIAL.LINKEDIN}/>
                                    <SocialIconLink to={"#"}/>
                                </SocialIconContainer>
                                <SocialIconContainer>
                                    <SocialIconImage src={Icons.SOCIAL.YOUTUBE}/>
                                    <SocialIconLink to={"#"}/>
                                </SocialIconContainer>
                            </SubRow3>
                        </Column3>
                    </SubRow2>
                </Column2>
            </Row1>
            <Row2>
                <Label text={t('footer-copyright')}/>
                <Label text={t('footer-eula')}/>
                <CharacterSeparator/>
                <Label text={t('footer-privacy')}/>
                <CharacterSeparator/>
                <Label text={t('footer-cookie')}/>
            </Row2>
        </FooContainer>
    );
};

export default Footer