import styled from "styled-components";
import {TooltipProps} from "../../types";
import {useState} from "react";
import {BreakpointsQuery} from "../../constants/device";
import {Color} from "../../constants/color";
import {Icons} from "../../constants/icons";


const TooltipWrapper = styled.div`
    display: flex;
    flex-direction: row;
    
`;

const TooltipIconWrapper = styled.button`
    position: relative;
    width: 16px;
    height: 16px;
    background: rgba(0, 0, 0, 0);
    border-style: none;
`;

const TooltipIcon = styled.img`
    width: 16px;
    height: 16px;
`;

const TooltipContainer = styled.div<{$visible: boolean}>`
    position: absolute;
    visibility: ${($visible) => $visible.$visible ? 'unset' : 'hidden'};
    bottom: 38%;
    left: 72%;
    width: 40%;
    background-color: ${Color.WHITE};
    border-style: solid;
    border-color: ${Color.LIGHT_BLUE};
    border-radius: 1rem 1rem 1rem 1rem;
    box-shadow: 0 0 6px 3px #E2E2E2;
    text-transform: none;
    font-size: 12px;
`;

const TooltipArrow = styled.div`
    content: '';
    position: inherit;
    display: inline-flex;
    width: 16px;
    height: 16px;
    right: 9.28rem;
    top: 64%;
    background-color: ${Color.WHITE};
    border-style: solid;
    border-color: ${Color.LIGHT_BLUE};
    transform: rotate(135deg);
    clip-path: polygon(54% 50%, 100% 4%, 100% 100%, 4% 100%);
    @media ${BreakpointsQuery.tablet} {
        right: 9.21rem;
    }
`;

const TooltipTitle = styled.p`
    font-weight: bold;
    
`;

const TooltipText = styled.p`
    font-size: 12px;
`;

const Tooltip = ({title, text} : TooltipProps) => {
    const [ visible, setTooltip ] = useState(false);
    return (
        <TooltipWrapper>
            <TooltipIconWrapper
                onClick={(event) => {
                    event.preventDefault();
                    setTooltip(!visible)
                }}
            >
                <TooltipIcon src={Icons.COMMONS.INFO}/>
            </TooltipIconWrapper>
            <TooltipContainer $visible={visible}>
                <TooltipArrow/>
                <TooltipTitle> {title} </TooltipTitle>
                <TooltipText> {text} </TooltipText>
            </TooltipContainer>
        </TooltipWrapper>
    );
}

export default Tooltip;