import styled from "styled-components";
import {LabelProps} from "../../types";
import {Color} from "../../constants/color";

const StyledLabel = styled.div <{
        $color: string,
        $textTransform: string,
        $textAlign: string,
        $fontSize: string,
        $fontWeight: string,
        $paddingLeft: number,
        $paddingBottom: number,
        $width: string
    }>`
    color: ${$color => $color.$color ?? Color.LIGHT_BLUE};
    font-family: ${$fontWeight => $fontWeight.$fontWeight === 'bold' ? 'Avenir Next Bold, sans-serif' : 'Avenir Next, sans-serif'};
    font-size: ${$fontSize => $fontSize.$fontSize ?? 'unset'};
    font-weight: ${$fontWeight => $fontWeight.$fontWeight ?? 'bold'};
    text-align: ${$textAlign => $textAlign.$textAlign ?? 'unset'};
    text-transform: ${$textTranform => $textTranform.$textTransform ?? 'none'};
    padding-left: ${$paddingLeft => $paddingLeft.$paddingLeft+'px' ?? '0'};
    padding-bottom: ${$paddingBottom => $paddingBottom.$paddingBottom+'px' ?? '0'};
    width: ${$width => $width.$width ?? 'unset'};
`;

const Label = ({color, text, textAlign, textTransform, fontSize , fontWeight, paddingLeft, paddingBottom, width}: LabelProps) => {
    return (
        <StyledLabel
            $color={color!!}
            $textTransform={textTransform!!}
            $textAlign={textAlign!!}
            $fontSize={fontSize!!}
            $fontWeight={fontWeight!!}
            $paddingLeft={paddingLeft!!}
            $paddingBottom={paddingBottom!!}
            $width={width!!}
        >
            {text}
        </StyledLabel>
    )
}

export default Label