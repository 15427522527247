import {useParams} from "react-router-dom";
import CourseDateCarousel from "../../components/course/date-carousel";
import styled from "styled-components";
import Title from "../../components/title";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import Label from "../../components/label";
import {useContext} from "react";
import {coursesPage} from "../../resources/mock";

const PageWrapper = styled.div`
    margin-top: 0.4rem;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
`;

const Course = () => {
    const { t } = useTranslation();
    let { name } = useParams();
    const mockCourse = useContext(coursesPage)
    const courseIndex = mockCourse.findIndex( el =>
        el.name.toLowerCase() === name
    )
    const course = mockCourse.at(courseIndex)
        return (
            <PageWrapper>
                <Title text={t('book-course-title')}/>
                <Label text={name!!}
                       fontSize={'20px'}
                       textTransform={'uppercase'}
                       color={Color.GREEN}
                       textAlign={'center'}
                       fontWeight={'bold'}
                />
                <CourseDateCarousel course={course!!}/>
            </PageWrapper>
        );
};

export default Course