import styled from "styled-components";
//import {useTranslation} from "react-i18next";
import {DropdownOptions} from "../../../../types";

const Option = styled.option`
    font-size: 15px;
`;

function Dropdown({value, label}: DropdownOptions) {
    //const { t } = useTranslation();
    return (
        <Option value={value} label={label}></Option>
    );
}

export default Dropdown