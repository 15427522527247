import { useState } from "react";
import * as Ariakit from "@ariakit/react";
import { ShieldCheck, XCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import "./fabbisognoformativo.css";

import { Color } from "../../constants/color";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
  height: 100%;
`;

const Kpi = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Color.LIGHT_BLUE};
  gap: 0.5em;
`;

const KpiLabel = styled.p<{ $fontSize: string }>`
  text-transform: uppercase;
  font-size: ${(props) => props.$fontSize};
  font-weight: bold;
  line-height: 1.1em;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0px;
  padding: 0.3em 1.5em;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 1.2em;
`;

const RowLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.6em;
  padding: 0.5em 1em;
  text-align: center;
  align-items: center;
`;

const THead = styled(RowLayout)`
  font-weight: bold;
  text-transform: uppercase;
  color: var(--blue300);
`;

const TRow = styled(RowLayout)`
  color: var(--blue500);
  font-weight: bold;
  text-transform: uppercase;
  text-wrap: balance;
  border: 1px solid var(--grey800);
  border-radius: 8px;
  background-color: var(--grey100);
`;

const TRowButton = styled.div`
  background-color: var(--blue500);
  color: var(--white);
  text-transform: uppercase;
  border-radius: 8px;
  padding: 0.3em 0.6em;
  flex-shrink: 0;
`;

const fabbisognoData = {
  inclusi: [
    {
      title: "SICUREZZA LAVORATORI PARTE GENERALE",
      fabbisogno: 15,
      attivi: 10,
      dafare: 5,
    },
    {
      title: "SICUREZZA LAVORATORI PARTE SPECIFICA RISCHIO BASSO",
      fabbisogno: 7,
      attivi: 6,
      dafare: 1,
    },
    {
      title: "SICUREZZA LAVORATORI PARTE SPECIFICA RISCHIO MEDIO",
      fabbisogno: 10,
      attivi: 8,
      dafare: 2,
    },
  ],
  esclusi: [
    {
      title: "AGGIORNAMENTO SICUREZZA LAVORATORI",
    },
    {
      title: "FORMAZIONE AGGIUNTIVA PER PREPOSTO",
    },
  ],
};

function FabbisognoFormativo() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const fabbisognoKpi = {
    data: {
      fabbisogno: 13,
      attivi: 11,
      dafare: 2,
    },
  };

  return (
    <Layout>
      <div
        style={{
          flexGrow: 0.4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "2em",
        }}
      >
        <ShieldCheck size={72} color={Color.GREEN} />
        <p
          style={{
            marginTop: "-5em",
            fontSize: "1.5em",
            lineHeight: "1.1em",
            color: Color.LIGHT_BLUE,
            fontWeight: "bolder",
            textAlign: "center",
            textWrap: "balance",
            textTransform: "capitalize",
            padding: "0em 4em",
          }}
        >
          {t("dashboard-training-needs")}
        </p>
        <p
          style={{
            marginTop: "-7em",
            fontSize: "1em",
            lineHeight: "1.1em",
            fontWeight: "bold",
            textAlign: "center",
            textWrap: "balance",
            textTransform: "uppercase",
            color: Color.GREEN,
          }}
        >
          {t("dashboard-description")}
        </p>
      </div>
      {Object.entries(fabbisognoKpi.data).map(([label, value]) => {
        return (
          <Kpi key={label}>
            <KpiLabel $fontSize="1.2rem">
              {t(`dashboard-${label}`, label)}
            </KpiLabel>
            <KpiLabel $fontSize="2rem">{value}</KpiLabel>
          </Kpi>
        );
      })}
      <div
        style={{
          flexShrink: 1,
          fontSize: "1.2em",
        }}
      >
        <OutlineButton onClick={() => setOpen(true)}>Calcola</OutlineButton>
      </div>

      <Ariakit.Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="dialog"
      >
        <Ariakit.DialogHeading className="heading">
          Fabbisogno formativo calcolato
          <Ariakit.DialogDismiss
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              border: "none",
              top: ".5em",
              right: ".5em",
              cursor: "pointer",
            }}
          >
            <XCircle size={24} color={Color.GREEN} />
          </Ariakit.DialogDismiss>
        </Ariakit.DialogHeading>
        <DialogContent>
          {/* TITOLI */}
          <THead>
            <p></p>
            <p>Fabbisogno formativo</p>
            <p>Attivi</p>
            <p>Da fare</p>
            <p></p>
          </THead>
          {/* ROWS TABLE */}
          {fabbisognoData.inclusi.map((row) => (
            <TRow key={row.title}>
              <p
                style={{
                  textAlign: "left",
                }}
              >
                {row.title}
              </p>
              <p>{row.fabbisogno}</p>
              <p>{row.attivi}</p>
              <p>{row.dafare}</p>
              <TRowButton>DETTAGLI</TRowButton>
            </TRow>
          ))}
          <p
            style={{
              color: "var(--blue500)",
              fontSize: "2em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Corsi non inclusi nel tuo pacchetto
          </p>
          <ul
            style={{
              color: "var(--blue500)",
              fontWeight: "bold",
              fontSize: "1.8em",
              marginLeft: "6em",
              marginBottom: "1em",
            }}
          >
            {fabbisognoData.esclusi.map((row) => (
              <li key={row.title}>{row.title}</li>
            ))}
          </ul>
        </DialogContent>
      </Ariakit.Dialog>
    </Layout>
  );
}

export default FabbisognoFormativo;
