import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { BreakpointsQuery } from "../../constants/device";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import {Icons} from "../../constants/icons";
import {useAuth} from "../../provider/authProvider";

const NavShadow = styled.div`
    box-shadow: 0 6px 3px -3px ${Color.SHADOW};
    display: flex;
    justify-content: center;
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: center;
    z-index: 100;
    height: 8.5rem;
    column-gap: 1.2rem;
    width: 54rem;
    text-align: center;
    @media ${BreakpointsQuery.tablet} {
        width: auto;
        column-gap: 0.8rem;
    }
`;

const NavLogoContainer = styled(NavLink)`
    display: inline-flex;
    width: 22%;
    padding-right: 2rem;
    @media ${BreakpointsQuery.tablet} {
        height: 98%;
        width: 18%;
        //padding: 0 calc((95vw - 1030px) / 6);
        padding-left: 0;
        padding-right: 2.5rem;
        column-gap: 0.8rem;
    }
`;

const NavLogo = styled.img`
    width: 110%;
    @media ${BreakpointsQuery.tablet} {
        width: 100%;
    }
`;

const NavButton = styled(NavLink)`
    color: ${Color.LIGHT_BLUE};
    height: 8rem;
    line-height: 14em;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bolder;
    font-family: "Avenir Next Bold", sans-serif;
    font-size: 13px;
`;

const NavLogin = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
    text-decoration: none;
    background-color: ${Color.LIGHT_BLUE};
    border-radius: 0 0 1.5rem 1.5rem;
    padding: 2.0rem 1.4rem 1.4rem;
    margin-bottom: 0.6rem;
`;

const NavLoginLogo = styled.img`
    width: 80%;
    margin: 20% auto auto;
    @media ${BreakpointsQuery.tablet} {
        margin-top: 22%
    }
`;

const NavLoginButton = styled(NavButton)`
    color: ${Color.WHITE};
    line-height: 2.2em;
`;

const NavBar = () => {
    const { t } = useTranslation();
    const { token, setToken } = useAuth()
    return (
        <NavShadow>
            <NavContainer>
                <NavLogoContainer to={'/'}>
                    <NavLogo alt={'logo'} src={Icons.LOGO.NAVBAR}/>
                </NavLogoContainer>
                <NavButton to={'/'}>{t('navbar-about')}</NavButton>
                <NavButton to={'/'}>{t('navbar-howto')}</NavButton>
                <NavButton to={'/'}>{t('navbar-courses')}</NavButton>
                <NavButton to={'/'}>{t('navbar-where')}</NavButton>
                <NavButton to={'/'}>{t('navbar-contacts')}</NavButton>
                <NavLogin>
                    <NavLoginLogo src={Icons.COMMONS.USER}/>
                    { token === undefined ?
                        <NavLoginButton to={'/login'}>{t('navbar-login')}</NavLoginButton>
                        :
                        <NavLoginButton to={''} onClick={ () => {
                            setToken('logout');
                            window.location.replace('/login')
                        }}>{t('navbar-logout')}</NavLoginButton>
                    }
                </NavLogin>
            </NavContainer>
        </NavShadow>
    );
};

export default NavBar