import {
  Bell,
  RefreshCcw,
  ScrollText,
  TriangleAlert,
  User,
} from "lucide-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import CorsiBarChart from "./CorsiBarChart";
import FormazioneBarChart from "./FormazioneBarChart";
import FabbisognoFormativo from "./FabbisognoFormativo";
import { Color } from "../../constants/color";
import ResetCss from "../ResetCss";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  row-gap: 2rem;
  column-gap: 1rem;
  justify-content: center;
  padding: 0.5em 1em;
`;

const Card = styled.div`
  border-radius: 8px;
  border: 2px solid var(--blue300);
`;

const CardShadowed = styled(Card)`
  border: none;
  box-shadow: 0px 10px 12px 0px var(--shadow800);
`;

const Title = styled.h1`
  color: var(--blue300);
  font-size: 40px;
  font-family: "Avenir Next Bold", sans-serif;
  font-weight: bold;
`;

const KpiLayout = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color};
`;

const KpiLabel = styled.p<{ $fontSize: string }>`
  font-weight: bold;
  font-size: ${(props) => props.$fontSize};
`;

export default function DashboardPage() {
  const { t } = useTranslation();
  const kpiData = {
    employees: 35,
    certificates: 48,
    expired: 2,
    expired_1m: 5,
    expired_6m: 4,
  };

  return (
    <ResetCss>
      <div
        style={{
          margin: "0 auto",
          width: "1300px",
        }}
      >
        <Layout>
          <div
            style={{
              gridColumn: "1/4",
              alignContent: "center",
              backgroundColor: "var(--gray500)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title>{t("dashboard-title")}</Title>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",
                color: "var(--green500)",
              }}
            >
              <RefreshCcw color={Color.GREEN} />
              <p style={{ fontWeight: "bold" }}>
                {t("dashboard-last-update")}: 12/03/2024 14:35
              </p>
            </div>
          </div>
          <CardShadowed
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "3fr 1fr 1fr 1fr",
                gap: "2rem",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1rem 2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                  height: "100%",
                  color: "var(--blue500)",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  <ScrollText color={Color.BLUE} width={48} height={48} />
                  <p
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bolder",
                      lineHeight: "1em",
                    }}
                  >
                    {t("dashboard-certificates")}
                  </p>
                </div>
                <p
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    textWrap: "balance",
                    lineHeight: "1.1em",
                  }}
                >
                  {t("dashboard-description")}
                </p>
              </div>
              {/* KPI SCADUTI */}
              <KpiLayout $color="var(--red500)">
                <Bell color={Color.RED} width={32} height={32} />
                <KpiLabel $fontSize="1rem">{t("dashboard-expired")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.expired}</KpiLabel>
              </KpiLayout>
              {/* KPI IN SCADENZA */}
              <KpiLayout $color="var(--orange500)">
                <Bell color={Color.ORANGE} width={32} height={32} />
                <KpiLabel $fontSize="1rem">
                  {t("dashboard-expiring")}
                </KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.expired_1m}</KpiLabel>
              </KpiLayout>
              {/* KPI VALIDI */}
              <KpiLayout $color="var(--green500)">
                <Bell color={Color.GREEN} width={32} height={32} />
                <KpiLabel $fontSize="1rem">{t("dashboard-valid")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">
                  {kpiData.certificates - kpiData.expired}
                </KpiLabel>
              </KpiLayout>
            </div>
          </CardShadowed>
          <Card>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "2rem",
                padding: "1rem 2rem",
                height: "100%",
                width: "100%",
              }}
            >
              {/* KPI DIPENDENTI */}
              <KpiLayout $color="var(--blue500)">
                <User color={Color.BLUE} width={40} height={40} />
                <KpiLabel $fontSize="1rem">
                  {t("dashboard-employees")}
                </KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.employees}</KpiLabel>
              </KpiLayout>
              {/* KPI ATTESTATI */}
              <KpiLayout $color="var(--yellow500)">
                <ScrollText color={Color.YELLOW} width={40} height={40} />
                <KpiLabel $fontSize="1rem">
                  {t("dashboard-certificates")}
                </KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.certificates}</KpiLabel>
              </KpiLayout>
              {/* KPI CRITICITA */}
              <KpiLayout $color="var(--red500)">
                <TriangleAlert color={Color.RED} width={40} height={40} />
                <KpiLabel $fontSize="1rem">
                  {t("dashboard-criticism")}
                </KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.expired}</KpiLabel>
              </KpiLayout>
            </div>
          </Card>

          {/* GRAFICI CORSI */}
          <Card style={{ gridRow: "3/4" }}>
            <CorsiBarChart chartWidth="100%" chartHeight="300px" />
          </Card>
          {/* GRAFICI FORMAZIONE */}
          <Card style={{ gridRow: "4/5" }}>
            <FormazioneBarChart chartWidth="100%" chartHeight="300px" />
          </Card>
          {/* FABBISOGNO FORMATIVO */}
          <CardShadowed style={{ gridColumn: "2/3", gridRow: "3/5" }}>
            <FabbisognoFormativo />
          </CardShadowed>
        </Layout>
      </div>
    </ResetCss>
  );
}
