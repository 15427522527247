export const BreakpointsSize = {
    mobile: 768,
    tablet: 1152,
    desktop: 1366,
};

export const BreakpointsQuery = {
    mobile: `(max-width: ${BreakpointsSize.mobile}px)`,
    tablet: `(max-width: ${BreakpointsSize.tablet}px)`,
    desktop: `(max-width: ${BreakpointsSize.desktop}px)`,
}