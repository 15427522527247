import styled from "styled-components";
import {useContext} from "react";
import {employeeData} from "../../../resources/mock";
import SingleEmployee from "./employee";
import Label from "../../label";
import {Color} from "../../../constants/color";
import Button from "../../button/button";
import {Icons} from "../../../constants/icons";
import {useTranslation} from "react-i18next";
import {BreakpointsQuery} from "../../../constants/device";


const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 75vw;
    overflow: auto;
`;

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: start;
    width: fit-content;
`;

const HeaderMargin = styled.div`
    width: 79rem;
    height: 0.2rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    background-color: ${Color.LIGHT_BLUE};
    @media ${BreakpointsQuery.tablet} {
        width: 104rem;
    }
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
    min-width: max-content;
    overflow-x: visible;
    overflow-y: auto;
    height: 22rem;
    justify-content: space-around;
`;

const EditButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1.2rem;
`;

const EmployeeTable = () => {
    const {t} = useTranslation()
    const employeeList = useContext(employeeData)
    return (
        <TableWrapper>
            <TableHeader>
                <Label text={'stato'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       textAlign={"center"}
                       width={"4rem"}
                       paddingLeft={4}
                />
                <Label text={'cognome'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       textAlign={"left"}
                       width={"4.6rem"}
                />
                <Label text={'nome'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       textAlign={"left"}
                       width={"4.8rem"}
                />
                <Label text={'codice fiscale'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={"10.2rem"}
                />
                <Label text={'mansione'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={"5.4rem"}
                />
                <Label text={'sede lavoro'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={"6.4rem"}
                />
                <Label text={'d / a'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={'4.5rem'}
                />
                <Label text={'d / c'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={'4.5rem'}
                />
                <Label text={'e-mail'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={'8.6rem'}
                />
                <Label text={'cellulare'}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                />
            </TableHeader>
            <HeaderMargin/>
            <ListWrapper>
                {employeeList.map((singleEmployee, index) => {
                    return (
                        <SingleEmployee
                            employee={singleEmployee}
                            key={singleEmployee.lastName + ' ' + singleEmployee.firstName}
                            index={index}
                        />
                    );
                })}
            </ListWrapper>
            <EditButtonsContainer>
                <Button label={t('Aggiungi personale')}
                        color={Color.GREEN}
                        icon={Icons.BUTTONS.PLUS}
                />
                <Button label={t('Importa da file')}
                        color={Color.BLUE}
                        icon={Icons.BUTTONS.UPLOAD}
                />
                <Button label={t('Modifica')}
                        color={Color.BLUE}
                        icon={Icons.BUTTONS.EDIT}
                />
                <Button label={t('Scarica template file import')}
                        color={Color.LIGHT_BLUE}
                        icon={Icons.BUTTONS.DOWNLOAD}
                />
            </EditButtonsContainer>
        </TableWrapper>
    );
};

export default EmployeeTable