import styled from "styled-components";
import {Color} from "../../../constants/color";


const StyledSeparator = styled.div`
    color: ${Color.LIGHT_BLUE};
    font-weight: bolder;
`;

const CharacterSeparator = () => {
    return (
        <StyledSeparator>
            |
        </StyledSeparator>
    )
}

export default CharacterSeparator