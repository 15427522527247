import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Color } from "../../../constants/color";
import { ReservedCourseItem } from "../../../types";
import { BreakpointsQuery } from "../../../constants/device";
import Label from "../../label";
import ContainerSeparator from "../../separator/container";
import Participants from "./participants";
import { useState } from "react";

const ReservedContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 78rem;
`;

const CourseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 6px 0 8px 4px ${Color.SHADOW};
  border-radius: 0.8rem;
  border-width: 40px;
  background-color: ${Color.WHITE};
  width: 78rem;
  height: 3.8rem;
  z-index: 1;
  @media ${BreakpointsQuery.tablet} {
    width: 62rem;
  }
`;

const ContainerLeftBorder = styled.div`
  position: relative;
  right: 0.4rem;
  box-shadow: 0 0 8px 6px ${Color.SHADOW};
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  border-width: 2rem;
  background-color: ${Color.LIGHT_BLUE};
  width: 1rem;
  height: 3.8rem;
`;

const CourseTypeIcon = styled.img`
  margin-right: 1.2rem;
  height: 80%;
`;

const CourseTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  min-width: 36rem;
`;

const LectureMode = styled.img`
  margin: 1rem;
  height: 60%;
`;

const CourseDate = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.6rem;
  text-align: center;
`;

const CourseAddress = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0.6rem;
  text-align: center;
`;

const DetailButton = styled.button`
  position: relative;
  background-color: ${Color.GREEN};
  color: ${Color.WHITE};
  font-weight: bold;
  font-size: 14px;
  border: none;
  height: 60%;
  width: 8rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const Course = (course: ReservedCourseItem) => {
  const { t } = useTranslation();
  const fontSize: string = "18px";
  const [open, setOpen] = useState(false);
  return (
    <ReservedContainer>
      <CourseContainer>
        <ContainerLeftBorder />
        <CourseTypeIcon src={course.type} />
        <CourseTitle>
          <Label
            text={course.courseName}
            fontSize={fontSize}
            fontWeight={"bold"}
            textTransform={'uppercase'}
          />
          <Label
            text={course.courseLevel ?? ''}
            fontSize={fontSize}
            fontWeight={"normal"}
          />
        </CourseTitle>
        <ContainerSeparator />
        <LectureMode src={course.lectureMode} />
        <ContainerSeparator />
        <CourseDate>
          <Label
            text={course.courseDate}
            color={Color.GREY}
            fontSize={"14px"}
            textTransform={"uppercase"}
          />
          <Label
            text={course.courseHours}
            color={Color.GREY}
            fontSize={"14px"}
            width={'6rem'}
          />
        </CourseDate>
        <ContainerSeparator />
        <CourseAddress>
          <Label
            text={course.city}
            color={Color.GREY}
            fontSize={"14px"}
            textTransform={"uppercase"}
          />
          <Label text={course.address} color={Color.GREY} fontSize={"14px"} />
        </CourseAddress>
        <DetailButton onClick={(e) => setOpen(!open)}>
          {t("reserved-courses-open-detail-button")}
        </DetailButton>
      </CourseContainer>
      <Participants show={open} participants={course.participants} />
    </ReservedContainer>
  );
};

export default Course;
