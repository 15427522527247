import {CoursePage} from "../../../types";
import CourseDateItem from "./date";
import styled from "styled-components";
import {useState} from "react";
import {useParams} from "react-router-dom";
import DateDetail from "../../book-course/carousel/item/detail";

interface Props {
    course: CoursePage,
}

const CarouselWrapper = styled.div`
    display: flex;
    column-gap: 1rem;
`;

const CourseDateCarousel = ( {course} : Props) => {
    let { date } = useParams();
    const dateSelected = course.availableDates.findIndex( el =>
        el.day.toString() === date
    )
    const [selected, setSelected] = useState(dateSelected);
    const handleSelected = (id : number) => setSelected(id);


    return (
        <>
        <CarouselWrapper>
            {course.availableDates.map((singleDate, index) => {
                return (
                    <CourseDateItem
                        date={singleDate}
                        key={index}
                        index={index}
                        selected={selected === index}
                        onClick={handleSelected}
                    />
                );
            })}
        </CarouselWrapper>
            { course.availableDates[selected] &&
                <DateDetail show={selected !== -1} date={course.availableDates[selected]} />
            }
        </>
    );
};

export default CourseDateCarousel