import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {AddressProps} from "../../../types";
import {Color} from "../../../constants/color";

const StyledAddress = styled(NavLink)`
    text-align: left;
    text-decoration: none;
    color: ${Color.LIGHT_BLUE};
    font-family: "Avenir Next", sans-serif;
    font-weight: bold;
`;

const Address = ( address : AddressProps) => {
    return (
        <StyledAddress to={`geo:${address.coordinate.latitude},${address.coordinate.longitude}`}>{address.label}</StyledAddress>
    )
}

export default Address;